import { useState } from 'react';

import {
  confirmPasswordValidator,
  passwordValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

// eslint-disable-next-line import/prefer-default-export
export const useNewPasswordValidator = (form) => {
  const [errors, setErrors] = useState({
    newPassword: {
      dirty: false,
      error: false,
      message: '',
    },
    repeatPassword: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { newPassword, repeatPassword } = form;

    if (nextErrors.newPassword.dirty && (field ? field === 'password' : true)) {
      const newPasswordMessage = passwordValidator(newPassword, form);
      nextErrors.newPassword.error = !!newPasswordMessage;
      nextErrors.newPassword.message = newPasswordMessage;
      if (newPasswordMessage) isValid = false;
    }

    if (
      nextErrors.repeatPassword.dirty &&
      (field ? field === 'password' : true)
    ) {
      const repeatPasswordMessage = confirmPasswordValidator(
        newPassword,
        repeatPassword,
        form
      );
      nextErrors.repeatPassword.error = !!repeatPasswordMessage;
      nextErrors.repeatPassword.message = repeatPasswordMessage;
      if (repeatPasswordMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};
