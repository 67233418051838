import React from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { persistor, store } from './data/redux/store/store';
import AppRouter from './main/routes/AppRouter';

import './presentation/assets/styles/styles.scss';
import 'material-icons/iconfont/material-icons.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <AppRouter />
      </PersistGate>
      <Toaster />
    </Provider>
  </React.StrictMode>
);
