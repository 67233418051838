import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getMeasurementUnit from '../../../data/redux/Units/thunks';
import { unitsOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import { setLoading } from '../../../data/redux/Units/measurementUnitSlice';
import Spinner from '../Spinner/Spinner';
import UnitsDesktop from './UnitsDesktop';
import UnitsMobile from './UnitsMobile';

function Units() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.measurementunit);
  useEffect(() => {
    dispatch(getMeasurementUnit());
    dispatch(setLoading(true));
  }, []);

  const [width, setWidth] = useState(1024);
  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return !loading ? (
    <div className="Units">
      <NavSec title="UNIDADES" content={ unitsOptions } />
      <div className="container-tablet-units">
        <div className="position-table">
          {width > 860 ? <UnitsDesktop /> : <UnitsMobile />}
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default Units;
