import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ordersOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import Table from '../Table/Table';
import { getOrdersbyClient } from '../../../data/redux/Orders/thunks';
import Spinner from '../Spinner/Spinner';
import {
  editCloseOrder,
  editOrdersClient,
} from '../../../data/redux/Orders/orderSlice';
import useOrdersColumns from './hooks/useOrdersColumns';

function ClientOrders() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ordersClient, loading } = useSelector((state) => state.order);
  const [filterOrders, setFilterOrders] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { columnsTableClientOrder, orders } = useOrdersColumns();
  const [newOrders, setNewOrders] = useState(ordersClient);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrdersbyClient(id));
  }, [id]);

  useEffect(() => {
    setNewOrders(ordersClient);
  }, [ordersClient]);

  useEffect(() => {
    setNewOrders(
      newOrders?.map((prevOrder) => {
        if (prevOrder.id === orders.id) {
          return {
            ...prevOrder,
            is_open: orders.is_open,
          };
        }
        return prevOrder;
      })
    );
  }, [orders]);

  useEffect(() => {
    dispatch(editCloseOrder(newOrders));
  }, [newOrders]);

  useEffect(() => {
    dispatch(editOrdersClient(newOrders));
  }, [newOrders]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleOrdersFilter = (e) => {
    switch (e.target.id) {
    case 'open':
      if (filterOrders === 'open') {
        setFilterOrders('');
      } else {
        setFilterOrders('open');
      }
      break;
    case 'close':
      if (filterOrders === 'close') {
        setFilterOrders('');
      } else {
        setFilterOrders('close');
      }
      break;

    default:
      break;
    }
  };

  useEffect(() => {
    if (filterOrders === 'open') {
      const filteredArr = newOrders?.filter((ord) => ord.is_open);
      setFilteredOrders(filteredArr);
    }
    if (filterOrders === 'close') {
      const filteredArr = newOrders?.filter((ord) => !ord.is_open);
      setFilteredOrders(filteredArr);
    }
  }, [filterOrders]);

  return !loading ? (
    <div className="client-orders-main">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      { newOrders?.length > 0 ? (
        <>
          <h1 className="client-orders-title">
            { newOrders[0].client.fullname }
          </h1>
          <Table
            className="marmoleria--datatable table-client-orders"
            selectRows={ false }
            columnsTable={ columnsTableClientOrder }
            filteredData={ filterOrders ? filteredOrders : newOrders }
            filterOrders={ filterOrders }
            expandedRow={ false }
            filterEnabled
            conceptsFilter={ [
              'id',
              'peso_total',
              'dollar_total',
              'user.fullName',
              'user.CUIT',
              'user.email',
              'order_status.order_status',
              'payment_status.payment_status',
            ] }
            section="order"
          />
        </>
      ) : (
        <h1 className="client-orders-title">
          ESTE CLIENTE AÚN NO REALIZÓ PEDIDOS
        </h1>
      ) }
      <div>
        <div className="container-btn-payment">
          <button
            onClick={ handleClose }
            className="btn-save btn-flex"
            type="button"
          >
            <span className="material-icons-outlined">arrow_back_ios</span>
          </button>
        </div>
        <div className="container-states">
          <button
            type="button"
            onClick={ handleOrdersFilter }
            className={ `cs-states ${filterOrders === 'open' ? 'filter_order-open--active' : ''
            }` }
          >
            <p id="open">Abierto</p>
            <span className="abierto ">.</span>
          </button>
          <button
            type="button"
            onClick={ handleOrdersFilter }
            className={ `cs-states ${filterOrders === 'close' ? 'filter_order-close--active' : ''
            }` }
          >
            <p id="close">Cerrado</p>
            <span className="cerrado ">.</span>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default ClientOrders;
