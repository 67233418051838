import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { articleOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import Spinner from '../Spinner/Spinner';
import ItemsDesktop from './ItemsDesktop';
import ItemsMobile from './ItemsMobile';
import getItem from '../../../data/redux/Items/thunks';
import { setLoading } from '../../../data/redux/Items/itemSlice';

function Items() {
  const [width, setWidth] = useState(1024);
  const { loading } = useSelector((state) => state.item);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItem());
    dispatch(setLoading(true));
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);

    if (window.innerWidth <= 860) setWidth(860);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return !loading ? (
    <div className="container-main-article">
      <NavSec title="ARTÍCULOS" content={ articleOptions } />

      {width > 860 ? <ItemsDesktop /> : <ItemsMobile />}
    </div>
  ) : (
    <Spinner />
  );
}

export default Items;
