import React from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { startLogin } from '../../../data/redux/auth/thunks';
import Fondo from '../../assets/images/fondo-login.svg';
import Logo from '../../assets/images/logo-myb-detalle.svg';
import useForm from '../../hooks/useForm';
import { useLoginValidator } from '../../hooks/useLoginValidator';
import InputField from '../InputField/InputField';

function FormLogin() {
  const dispatch = useDispatch();

  const { formState, setFormState } = useForm({
    email: '',
    password: '',
  });

  const { errors, validateForm, onBlurField } = useLoginValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return;

    dispatch(startLogin(formState));
  };
  return (
    <div className="container-main-login">
      <div className="container-card-main">
        <div className="img_container">
          <img
            src={ Fondo }
            alt="Logo Marmoleria YB"
            className="img--background"
          />
          <img src={ Logo } alt="Logo Marmoleria YB" className="img-logo" />
        </div>
        <form
          action=""
          className="container-form-loging"
          onSubmit={ handleFormSubmit }
        >
          <InputField
            value={ formState.email }
            type="text"
            name="email"
            label="EMAIL"
            className="column"
            onBlur={ onBlurField }
            onChange={ onInputChange }
          />
          {errors.email.dirty && errors.email.error ? (
            <div className="columns m-0 mb-2 is-centered">
              <p className="error">{errors.email.message}</p>
            </div>
          ) : null}
          <InputField
            value={ formState.password }
            type="password"
            name="password"
            label="CONTRASEÑA"
            className=""
            onChange={ onInputChange }
          />
          <div className="columns m-0 mb-2 is-centered">
            {errors.password.dirty && errors.password.error ? (
              <p className="error">{errors.password.message}</p>
            ) : null}
          </div>
          <button className="button--session" size="lg" type="submit">
            ENTRAR
          </button>
        </form>
      </div>
      <div className="container-recovery-pass">
        <Link to="/recovery">
          <p className="recovery-pass">¿Olvidaste tu contraseña?</p>
        </Link>
      </div>
    </div>
  );
}
export default FormLogin;
