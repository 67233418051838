import toast from 'react-hot-toast';
import calculateCommission from './calculateCommission';

function calculateItemPrice(item, formState, commissions, client) {
  if ((!item, !formState, !commissions, !client))
    return toast.error('Error calculando el precio, revise los datos');
  let itemPrice = 0;
  let itemBonification = 0;
  let itemCommission = 0;
  if (item.detail.measurement_unit.unit_type.id === 2) {
    itemPrice = item.price * Number(item.width) * Number(item.quantity);
  } else if (item.detail.measurement_unit.unit_type.id === 3) {
    itemPrice =
      item.price *
      Number(item.width) *
      Number(item.height) *
      Number(item.quantity);
  } else if (item.detail.measurement_unit.unit_type.id === 4) {
    itemPrice =
      item.price *
      Number(item.width) *
      Number(item.height) *
      Number(item.depth) *
      Number(item.quantity);
  } else {
    itemPrice = item.price * Number(item.quantity);
  }
  if (item.detail.nomenclature.bonusable) {
    itemBonification = itemPrice * Number(item.bonification) * 0.01;
    const nomenclatureId = item?.detail?.nomenclature?.id;
    const commissionPercent = calculateCommission(
      nomenclatureId,
      formState,
      commissions,
      client
    );
    itemCommission = (itemPrice - itemBonification) * commissionPercent * 0.01;
  }
  return {
    itemPrice, itemBonification, itemCommission
  };
}

export default calculateItemPrice;
