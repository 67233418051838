import { rolesOptions } from '../../../../helpers/dataOptions';
import ButtonUserMod from '../components/ButtonUserMod';

const columnsTableMobile = [
  {
    name: 'ROL',
    selector: (row) => {
      const roleName = row?.roles?.[0]?.name;
      const roleOption = rolesOptions.find((option) => option.name === roleName);
      const displayName = roleOption ? roleOption.displayName : '';
      return displayName;
    },
    sortable: true,
    minWidth: '42%',
  },
  {
    name: 'NOMBRE',
    selector: (row) => row?.fullName,
    sortable: true,
    minWidth: '42%',
  },
  {
    name: '',
    cell: (row) => ButtonUserMod(row),
    sortable: true,
    button: true,
    minWidth: '10%',
  },
];

export default columnsTableMobile;
