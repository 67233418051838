import { useEffect, useRef } from 'react';

const useElementHider = (stateSetter) => {
  const domElementRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        domElementRef.current &&
        !domElementRef.current.contains(event.target)
      ) {
        stateSetter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [domElementRef]);

  return domElementRef;
};

export default useElementHider;
