import React from 'react';

import { useDispatch } from 'react-redux';
import { startRecoveryPassword } from '../../../data/redux/auth/thunks';
import Fondo from '../../assets/images/fondo-login.svg';
import Logo from '../../assets/images/logo-ext-myb.svg';
import useForm from '../../hooks/useForm';
import { useRecoverPasswordValidator } from '../../hooks/useRecoverPasswordValidator';
import InputField from '../InputField/InputField';

function RecoverPassword() {
  const dispatch = useDispatch();

  const { formState, setFormState } = useForm({
    email: '',
  });

  const { errors, validateForm, onBlurField } =
    useRecoverPasswordValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return;

    dispatch(startRecoveryPassword(formState));
  };
  return (
    <div className="container-main-login">
      <div className="container-card-main">
        <div className="img_container">
          <img
            src={ Fondo }
            alt="Logo Marmoleria YB"
            className="img--background"
          />
          <img src={ Logo } alt="Logo Marmoleria YB" className="img-logo" />
        </div>
        <form
          action=""
          className="container-form-loging"
          onSubmit={ handleFormSubmit }
        >
          <InputField
            value={ formState.email }
            type="text"
            name="email"
            label="INGRESE EMAIL"
            className="column"
            onBlur={ onBlurField }
            onChange={ onInputChange }
          />
          {errors.email.dirty && errors.email.error ? (
            <div className="columns m-0 mb-2 is-centered">
              <p className="error">{errors.email.message}</p>
            </div>
          ) : null}
          <button
            className="button--session"
            size="lg"
            type="submit"
            onClick={ handleFormSubmit }
          >
            ENVIAR
          </button>
        </form>
      </div>
    </div>
  );
}
export default RecoverPassword;
