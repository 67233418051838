/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getClient from './thunks';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    client: [],
    editClients: null,
    clientComm: null,
    auditComm: null,
    loading: true,
    nomenclature: [],
  },

  reducers: {
    addClient: (state, { payload }) => {
      state.client = [...state.client, {
        ...payload
      }];
      state.editClients = null;
    },

    editClient: (state, { payload }) => {
      state.client = state.client.map((client) =>
        client.id === payload.id ? payload : client
      );
      state.editClients = null;
      state.clientComm = null;
    },

    deleteClient: (state, { payload }) => {
      state.client = state.client.filter((client) => client.id !== payload);
      state.loading = false;
    },

    selectClient: (state, { payload }) => {
      state.editClients = state.client.find(
        (client) => client.id === payload.id
      );
    },

    onLoadingClient: (state) => {
      state.loading = true;
    },

    editClients: (state, { payload }) => {
      state.client = payload;
    },
    selectNomenclatures: (state, { payload }) => {
      state.nomenclature = payload.unit_types;
      state.loading = false;
    },
    selectClientComms: (state, { payload }) => {
      state.clientComm = payload;
      state.loading = false;
    },
    editClientComm: (state, { payload }) => {
      state.clientComm = payload;
    },
    selectAuditComms: (state, { payload }) => {
      state.auditComm = payload;
      state.loading = false;
    },
    editAuditComm: (state, { payload }) => {
      state.auditComm = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClient.fulfilled, (state, { payload }) => {
      state.client = payload;
      state.editClients = null;
      state.clientComm = null;
      state.auditComm = null;
      state.loading = false;
    });
    builder.addCase(getClient.rejected, (state) => {
      state.client = 'fail';
      state.editClients = null;
      state.clientComm = null;
      state.auditComm = null;
      state.loading = false;
    });
  },
});

export const {
  addClient,
  editClient,
  deleteClient,
  selectClient,
  onLoadingClient,
  editClients,
  selectNomenclatures,
  selectClientComms,
  editClientComm,
  selectAuditComms,
  editAuditComm,
  setLoading,
} = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
