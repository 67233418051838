import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { startResetPassword } from '../../../data/redux/auth/thunks';
import Fondo from '../../assets/images/fondo-login.svg';
import Logo from '../../assets/images/logo-myb-detalle.svg';
import useForm from '../../hooks/useForm';
import { useNewPasswordValidator } from '../../hooks/useNewPasswordValidator';
import InputField from '../InputField/InputField';

function FormNewPassword() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();

  const { formState, setFormState } = useForm({
    newPassword: '',
    repeatPassword: '',
  });

  const { errors, validateForm, onBlurField } =
    useNewPasswordValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return;

    formState.token = token;

    dispatch(startResetPassword(formState, navigate));
  };

  return (
    <div className="container-main-login">
      <div className="container-card-main">
        <div className="img_container">
          <img
            src={ Fondo }
            alt="Logo Marmoleria YB"
            className="img--background"
          />
          <img src={ Logo } alt="Logo Marmoleria YB" className="img-logo" />
        </div>
        <form
          action=""
          className="container-form-loging"
          onSubmit={ handleFormSubmit }
        >
          <InputField
            value={ formState.newPassword }
            type="password"
            name="newPassword"
            label="NUEVA CONTRASEÑA"
            className="column"
            onBlur={ onBlurField }
            onChange={ onInputChange }
          />
          {errors.newPassword.dirty && errors.newPassword.error ? (
            <div className="columns m-0 mb-2 is-centered">
              <p className="error">{errors.newPassword.message}</p>
            </div>
          ) : null}
          <InputField
            value={ formState.repeatPassword }
            type="password"
            name="repeatPassword"
            label="REPITA CONTRASEÑA"
            className="column"
            onChange={ onInputChange }
          />
          {errors.repeatPassword.dirty && errors.repeatPassword.error ? (
            <div className="columns m-0 mb-2 is-centered">
              <p className="error">{errors.repeatPassword.message}</p>
            </div>
          ) : null}
          <div className="columns m-0 mb-2 is-centered ">
            <button className="button--session" size="lg" type="submit">
              CAMBIAR
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default FormNewPassword;
