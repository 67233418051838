import { useState } from 'react';

const useForm = (initialForm = {
}) => {
  const [formState, setFormState] = useState(initialForm);
  const [error, setError] = useState(false);

  const onInputChange = ({ target }) => {
    const { name, value, type, checked } = target;

    setFormState({
      ...formState,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEnableChange = () => {
    setFormState((prevState) => ({
      ...prevState,
      enabled: !prevState.enabled,
    }));
  };

  const resetForm = () => {
    setFormState(initialForm);
  };

  return {
    ...formState,
    formState,
    setFormState,
    onInputChange,
    resetForm,
    error,
    setError,
    handleEnableChange,
  };
};

export default useForm;
