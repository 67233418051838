import React from 'react';
import { Navigate } from 'react-router-dom';
import Items from '../components/Items/Items';
import ItemsForm from '../components/Items/ItemsForm';
import Client from '../components/Client/Client';
import ClientForm from '../components/Client/forms/ClientForm';
import ClientOrders from '../components/Client/ClientOrders';
import ConfigEmpresa from '../components/Config/ConfigEmpresa';
import ConfigUser from '../components/Config/ConfigUser';
import OrdersForm from '../components/Orders/OrdersForm';
import Orders from '../components/Orders/Orders';
import OrdersFinal from '../components/Orders/OrdersFinal';
import Payment from '../components/Orders/Payment';
import Units from '../components/Units/Units';
import UnitsForm from '../components/Units/UnitsForm';
import UserComponent from '../components/User/UserComponent';
import UserForm from '../components/User/UserForm';
import Reports from '../components/Reports/Reports';
import ReportsGenerated from '../components/Reports/ReportsGenerated';
import ReportFinal from '../components/Reports/ReportFinal';
import Home from './Home';

const protectedPagesData = [
  {
    path: '*',
    element: <Navigate to="/home" />,
    title: 'Not Found',
    category: ['all'],
  },
  {
    path: '',
    element: <Navigate to="/home" />,
    title: 'Not Found',
    category: ['all'],
  },
  {
    path: 'home',
    element: <Home />,
    title: 'Home',
    category: ['all'],
  },
  {
    path: 'user',
    element: <UserComponent />,
    title: 'User',
    category: ['user-read', 'user-delete'],
  },
  {
    path: 'user/form',
    element: <UserForm />,
    title: 'User Form',
    category: ['user-update', 'user-create'],
  },
  {
    path: 'user/config',
    element: <ConfigUser />,
    title: 'User Config',
    category: ['all'],
  },
  {
    path: 'config',
    element: <ConfigEmpresa />,
    title: 'Config',
    category: ['config-update'],
  },
  {
    path: 'article',
    element: <Items />,
    title: 'Article',
    category: ['item-read', 'item-delete'],
  },
  {
    path: 'article/form',
    element: <ItemsForm />,
    title: 'Article Form',
    category: ['item-update', 'item-create'],
  },
  {
    path: 'client',
    element: <Client />,
    title: 'Client',
    category: ['client-delete', 'client-read'],
  },
  {
    path: 'client/form',
    element: <ClientForm />,
    title: 'Client Form',
    category: ['client-create', 'client-update', 'client-update-commission'],
  },
  {
    path: 'client/orders/:id',
    element: <ClientOrders />,
    title: 'Client Orders',
    category: ['client-delete', 'client-read', 'order-read', 'order-update'],
  },
  {
    path: 'reports',
    element: <ReportsGenerated />,
    title: 'reports',
    category: ['report-read'],
  },
  {
    path: 'reports/generated',
    element: <Reports />,
    title: 'reports',
    category: ['report-read'],
  },
  {
    path: 'reports/final',
    element: <ReportFinal />,
    title: 'reports',
    category: ['report-read'],
  },
  {
    path: 'orders',
    element: <Orders />,
    title: 'Orders',
    category: ['order-read', 'order-update', 'order-delete'],
  },
  {
    path: 'orders/form',
    element: <OrdersForm />,
    title: 'Orders Form',
    category: ['order-create', 'order-update'],
  },
  {
    path: 'orders/final',
    element: <OrdersFinal />,
    title: 'Orders final',
    category: ['order-read', 'order-update'],
  },
  {
    path: 'orders/payment',
    element: <Payment />,
    title: 'Orders Payment',
    category: ['order-read', 'order-update'],
  },
  {
    path: 'units',
    element: <Units />,
    title: 'Units',
    category: ['unit-read', 'unit-delete'],
  },
  {
    path: 'units/form',
    element: <UnitsForm />,
    title: 'Units Form',
    category: ['unit-create', 'unit-update'],
  },
];

export default protectedPagesData;
