import { useState } from 'react';
import {
  cuitValidator,
  emailValidator,
  fullNameValidator,
  phoneValidator,
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useUsersValidator = (formState) => {
  const [errors, setErrors] = useState({
    fullName: {
      dirty: false,
      error: false,
      message: '',
    },
    CUIT: {
      dirty: false,
      error: false,
      message: '',
    },
    email: {
      dirty: false,
      error: false,
      message: '',
    },
    address: {
      dirty: false,
      error: false,
      message: '',
    },
    phone: {
      dirty: false,
      error: false,
      message: '',
    },
    enabled: {
      dirty: false,
      error: false,
      message: '',
    },
    roles: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { fullName, CUIT, email, address, phone, roles } = form;

    if (nextErrors.fullName.dirty && (field ? field === 'fullName' : true)) {
      const fullNameMessage = fullNameValidator(fullName, form);
      nextErrors.fullName.error = !!fullNameMessage;
      nextErrors.fullName.message = fullNameMessage;
      if (fullNameMessage) isValid = false;
    }

    if (nextErrors.CUIT.dirty && (field ? field === 'CUIT' : true)) {
      const CUITMessage = cuitValidator(CUIT, form);
      nextErrors.CUIT.error = !!CUITMessage;
      nextErrors.CUIT.message = CUITMessage;
      if (CUITMessage) isValid = false;
    }

    if (nextErrors.email.dirty && (field ? field === 'email' : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (emailMessage) isValid = false;
    }

    if (nextErrors.address.dirty && (field ? field === 'address' : true)) {
      const addressMessage = textValidator(address, form);
      nextErrors.address.error = !!addressMessage;
      nextErrors.address.message = addressMessage;
      if (addressMessage) isValid = false;
    }

    if (nextErrors.phone.dirty && (field ? field === 'phone' : true)) {
      const phoneMessage = phoneValidator(phone, form);
      nextErrors.phone.error = !!phoneMessage;
      nextErrors.phone.message = phoneMessage;
      if (phoneMessage) isValid = false;
    }

    if (nextErrors.roles.dirty && (field ? field === 'roles' : true)) {
      const rolesMessage = selectValidator(roles);
      nextErrors.roles.error = !!rolesMessage;
      nextErrors.roles.message = rolesMessage;
      if (rolesMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };
    validateForm({
      form: formState, field, errors: updatedErrors
    });
  };

  return {
    ...formState,
    formState,
    errors,
    validateForm,
    onBlurField,
  };
};

export default useUsersValidator;
