/* eslint-disable jsx-a11y/label-has-associated-control */
import { getConfig } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { editConfig } from '../../../data/redux/UserConfig/thunks';
import { onLoadingUserConfig } from '../../../data/redux/UserConfig/userConfigSlice';
import useConfigValidator from '../../hooks/useConfigValidator';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import Spinner from '../Spinner/Spinner';

function ConfigEmpresa() {
  const dispatch = useDispatch();
  const { userConfig, loading, editConfigs } = useSelector(
    (state) => state.userconfig
  );
  const { permissions } = useSelector((state) => state.auth);
  function updateFileLabel(name) {
    const fileInput = document.getElementById(`${name}`);
    const fileLabel = document.getElementById(`${name}`);

    if (fileInput) {
      if (fileInput?.value) {
        fileLabel.innerText = fileInput.value.split('\\').pop(); // Muestra solo el nombre del archivo
      } else {
        // console.log('👀 ~ name:', fileInput.value)
        fileLabel.innerText = 'Choose file';
      }
    }
  }
  const { formState, setFormState } = useForm({
    company_name: userConfig.company_name,
    cuit: userConfig.cuit,
    iva_position: userConfig.iva_position,
    address: userConfig.address,
    phone: userConfig.phone,
    email: userConfig.email,
    footer: userConfig.footer,
    company_sign: userConfig.company_sign,
    company_logo: userConfig.company_logo,
  });

  useEffect(() => {
    getConfig();
  }, [userConfig]);

  useEffect(() => {
    updateFileLabel('company_logo');
    updateFileLabel('company_sign');
  }, [formState.company_logo]);

  const { errors, validateForm, onBlurField } = useConfigValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const newForm = new FormData();
  newForm.append('company_name', formState.company_name);
  newForm.append('cuit', formState.cuit);
  newForm.append('iva_position', formState.iva_position);
  newForm.append('address', formState.address);
  newForm.append('phone', formState.phone);
  newForm.append('email', formState.email);
  newForm.append('footer', formState.footer);
  // if (formState.company_logo)
  newForm.append('company_logo', formState.company_logo);
  // if (formState.company_sign)
  newForm.append('company_sign', formState.company_sign);
  // newForm.append(
  //   'company_logo',
  //   !formState.company_logo ? '' : formState.company_logo
  // )
  // newForm.append(
  //   'company_sign',
  //   !formState.company_sign ? '' : formState.company_sign
  // )
  const [toggle, setToggle] = useState(true);

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(onLoadingUserConfig());

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    dispatch(editConfig({
      _body: newForm
    }));
    return null;
  };

  function datos() {
    setToggle(true);
  }

  function imagenes() {
    setToggle(false);
  }

  const onChangeFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;

    const file = files[0];
    if (
      file &&
      (file.name.endsWith('.jpg') ||
        file.name.endsWith('.png') ||
        file.name.endsWith('.webp'))
    ) {
      setFormState({
        ...formState,
        [name]: file,
      });

      if (name === 'company_logo' || name === 'company_sign')
        updateFileLabel(name);

      return toast.success('Archivo cargado correctamente');
    }
    return toast.error('El formato debe ser .jpg .png o .webp');
  };

  return !loading && editConfigs ? (
    <div className="container-config">
      <div className="container-config-background">.</div>
      <div className="container-config-user">
        <h2 className="title-config">CONFIGURACIONES</h2>
        <div className="container-btn-empresa">
          <button
            onClick={ () => datos() }
            type="button"
            className={ toggle ? 'activo' : 'title-config-btn btn-empresa' }
          >
            DATOS
          </button>
          <button
            onClick={ () => imagenes() }
            type="button"
            className={ toggle ? 'title-config-btn btn-empresa' : 'activo' }
          >
            IMÁGENES
          </button>
        </div>
        <form action="" onSubmit={ handleEditSubmit } className="form-config">
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* NOMBRE DE LA EMPRESA"
                    name="company_name"
                    value={ formState.company_name }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.company_name.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.company_name.dirty && errors.company_name.error ? (
                    <p className="error-validation-w">
                      {errors.company_name.message}
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* CUIT"
                    name="cuit"
                    value={ formState.cuit }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.cuit.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.cuit.dirty && errors.cuit.error ? (
                    <p className="error-validation-w">{errors.cuit.message}</p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="POSICIÓN FRENTE AL IVA"
                    name="iva_position"
                    value={ formState.iva_position }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.iva_position.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.iva_position.dirty && errors.iva_position.error ? (
                    <p className="error-validation-w">
                      {errors.iva_position.message}
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* DIRECCIÓN"
                    name="address"
                    value={ formState.address }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.address.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.address.dirty && errors.address.error ? (
                    <p className="error-validation-w">
                      {errors.address.message}
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* TELÉFONO"
                    name="phone"
                    value={ formState.phone }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.phone.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.phone.dirty && errors.phone.error ? (
                    <p className="error-validation-w">{errors.phone.message}</p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config">
            {toggle === true ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* EMAIL"
                    name="email"
                    value={ formState.email }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    className={ errors.email.error ? 'red-advertise' : '' }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.email.dirty && errors.email.error ? (
                    <p className="error-validation-w">{errors.email.message}</p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="input-config input-config-img">
            {toggle === false ? (
              <div className="ctn-input input-file">
                {!userConfig.company_logo ? (
                  <div className="img-config--container">
                    <span className="material-icons-round material-symbols-outlined">
                      imagesmode
                    </span>
                  </div>
                ) : (
                  <div className="img-config--container">
                    <img
                      src={ `${process.env.REACT_APP_URL}${userConfig.company_logo}` }
                      alt="logo"
                      className="img-logo"
                    />
                  </div>
                )}
                <label htmlFor="company_logo" className="config_label">
                  * LOGOTIPO DE LA EMPRESA
                </label>
                {permissions.includes('config-update') ? (
                  <input
                    // label="* LOGOTIPO DE LA EMPRESA"
                    id="company_logo"
                    name="company_logo"
                    className="file-input-img"
                    type="file"
                    onChange={ onChangeFile }
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="input-config input-config-img">
            {toggle === false ? (
              <div className="ctn-input input-file">
                {!userConfig.company_sign ? (
                  <div className="img-config--container">
                    <span className="material-icons-round material-symbols-outlined">
                      imagesmode
                    </span>
                  </div>
                ) : (
                  <div className="img-config--container">
                    <img
                      src={ `${process.env.REACT_APP_URL}${userConfig.company_sign}` }
                      alt="logo"
                      className="img-logo"
                    />
                  </div>
                )}
                <label className="config_label" htmlFor="company_sign">
                  * FIRMA DE LA EMPRESA
                </label>
                {permissions.includes('config-update') ? (
                  <input
                    // label="* FIRMA DE LA EMPRESA"
                    name="company_sign"
                    id="company_sign"
                    className="file-input-img"
                    type="file"
                    onChange={ onChangeFile }
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="input-config input-config-img">
            {toggle === false ? (
              <>
                <div className="ctn-input">
                  <InputField
                    label="* PIE DE PÁGINA"
                    name="footer"
                    value={ formState.footer }
                    type="text"
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                    disabled={ !permissions.includes('config-update') }
                  />
                </div>
                <div className="container-client-form container-form">
                  {errors.footer.dirty && errors.footer.error ? (
                    <p className="error-validation-w">
                      {errors.footer.message}
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="container-btn-config">
            {permissions.includes('config-update') ? (
              <button className="save-sesion-btn" type="submit">
                <span className="material-icons-outlined">save</span>GUARDAR
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default ConfigEmpresa;
