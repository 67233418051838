import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

// eslint-disable-next-line import/no-cycle
import {
  addItem,
  deleteItem,
  editItem,
  editItems,
  loadMassiveItem,
  onLoadingItem,
  selectCurrencies,
  selectItemTypes,
  selectMUnits,
  selectNomenclatures,
  uploadMassiveItem,
} from './itemSlice';
import convertToCSV from '../../../presentation/utils/convertToCSV';

const getItem = createAsyncThunk('item/getItems', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/item`,
      config()
    );
    return data.original;
  } catch (error) {
    return error;
  }
});

export default getItem;

export const createItem = (form) => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/item`,
      form,
      config()
    );
    dispatch(addItem(data.page));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const editsItem =
  ({ id, _body }) =>
    async (dispatch) => {
      dispatch(onLoadingItem());
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/item/${id}`,
          _body,
          config()
        );
        dispatch(editItem(data.page));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const deletesItem = (id) => async (dispatch) => {
  // dispatch(onLoadingItem())
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}api/item/${id}`,
      config()
    );
    dispatch(deleteItem(id));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getNomenclatures = () => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/nomenclatures`,
      config()
    );
    dispatch(selectNomenclatures(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const loadMassiveItems = (form, item) => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/item-load-massive`,
      form,
      config()
    );
    if (data.data.item_status) {
      const headers =
        'Id,Articulo,Nomenclatura,Unidad_de_Medida,Largo,Alto,Espesor,Moneda,Costo,Precio,Descripcion,Activo,Estado';
      const csvData = convertToCSV(data.data.array_res);
      const fileContent = `${headers}\n${csvData}`;
      const link = document.createElement('a');
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        fileContent
      )}`;
      const fileName = 'status-items.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }

    dispatch(loadMassiveItem(data.data));

    const newArray = [...item, ...data.data.array_res];
    dispatch(editItems(newArray));
    dispatch(getItem());
    return data.data.item_status
      ? toast.error(data.message)
      : toast.success(data.message);
  } catch (data) {
    // console.log('data catch', data)
    dispatch(loadMassiveItem());
    return toast.error(data.response.data.message);
  }
};

export const getItemTypes = () => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/item-types`,
      config()
    );
    dispatch(selectItemTypes(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getCurrencies = () => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/currencies`,
      config()
    );
    dispatch(selectCurrencies(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getMUnits = () => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/measurement-unit`,
      config()
    );
    dispatch(selectMUnits(data.original));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getDownloadItems = async () => {
  axios
    .get(`${process.env.REACT_APP_API_BACKEND}api/item-download`, config())
    .then((resp) => {
      if (resp.status === 200) {
        const link = document.createElement('a');
        link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
          resp.data
        )}`;
        const currentDate = new Date();
        const fileName = `item-${currentDate.getFullYear()}-${currentDate.getMonth() + 1
        }-${currentDate.getDate()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((resp) => {
      toast.error(resp.response.data.message);
    });
};

export const uploadMassiveItems = (form) => async (dispatch) => {
  dispatch(onLoadingItem());
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_BACKEND}api/item-upload-massive`,
      form,
      config()
    );

    dispatch(uploadMassiveItem());

    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const downloadTemplate = async () => {
  const data =
    'Id,Articulo,Nomenclatura,Unidad_de_Medida,Largo,Alto,Espesor,Moneda,Costo,Precio,Descripcion,Activo';
  const link = document.createElement('a');
  link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  const fileName = 'template-items.csv';
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  toast(
    'Recuerda que el archivo para subir debe ser formato .csv y se recomienda editarlo con Google Sheets',
    {
      icon: '👏',
      duration: 6000,
    }
  );
};
