import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Router from './Router';

function AppRouter() {
  // Uncomment below line for protect routes by user roles
  const { status, permissions } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      {status === 'authenticated' ? (
        <ProtectedRoute
          isAuthenticated={ status }
          permissions={ permissions }
          isAllowed
        />
      ) : (
        <Router />
      )}
    </BrowserRouter>
  );
}

export default AppRouter;
