function calculateCommission(nomenclatureId, formState, commissions, client) {
  if (client?.client_type?.id === 1) {
    if (commissions?.some((comm) => comm.nomenclature.id === nomenclatureId)) {
      return commissions.filter(
        (comm) => comm.nomenclature.id === nomenclatureId
      )[0].commission;
    }
  } else if (formState.specialComm) {
    if (
      client?.special_comms?.some(
        (comm) => comm.nomenclature.id === nomenclatureId
      )
    ) {
      return client?.special_comms.filter(
        (comm) => comm.nomenclature.id === nomenclatureId
      )[0].commission;
    }
    return 0;
  } else {
    if (
      client?.commissions?.some(
        (comm) => comm.nomenclature.id === nomenclatureId
      )
    ) {
      return client?.commissions.filter(
        (comm) => comm.nomenclature.id === nomenclatureId
      )[0].commission;
    }
    return 0;
  }
  return 0;
}
export default calculateCommission;
