import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { validateInput } from '../../../validation/validators/validateInput';

function InputField({
  value,
  defaultValue,
  name,
  label,
  placeholder,
  validators,
  onBlur,
  className,
  type,
  onChange,
  disabled,
  min,
  max,
  isDecimal,
}) {
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChange = (e) => {
    setError(validateInput(validators, e.target.value));
    if (type === 'number' || type === 'amount') {
      let inputValue = e.target.value;
      if (isDecimal) {
        // const decimalRegex = /^\d+(\.\d{0,2})?$/
        const decimalRegex = /^(\d+([.,]?\d{0,2})?)?$/;
        inputValue = inputValue.replace(/[^0-9.]/g, '');
        if (!decimalRegex.test(inputValue)) {
          e.target.value = inputValue.slice(0, -1);
          return;
        }
      } else {
        inputValue = inputValue.replace(/[^0-9]/g, '');
      }
      e.target.value = inputValue;
      onChange(e);
    } else {
      onChange(e);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  switch (type) {
  case 'textarea':
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}
        <textarea
          className={ `form__input ${error && 'form__input--error'
          } ${className}` }
          placeholder={ placeholder }
          name={ name }
          value={ value }
          defaultValue={ defaultValue }
          onChange={ handleOnChange }
          onBlur={ onBlur }
          disabled={ disabled }
        />
        {error && <span className="form__error">{error.message}</span>}
      </div>
    );

  case 'password':
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}
        <div
          className={ `form__input ${error && 'form__input--error'
          } ${className} password--container` }
        >
          <input
            type={ showPassword ? 'text' : 'password' }
            value={ value }
            name={ name }
            placeholder={ placeholder }
            className={ `form__input ${error && 'form__input--error'
            } password--input` }
            onChange={ handleOnChange }
            onBlur={ onBlur }
            disabled={ disabled }
          />

          <button
            className="show_password--container"
            type="button"
            onClick={ handleShowPassword }
          >
            <span className="material-symbols-outlined material-icons-round">
              {showPassword ? 'visibility_off' : 'visibility'}
            </span>
          </button>
        </div>

        {error && <span className="form__error">{error.message}</span>}
      </div>
    );

  case 'number':
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}
        <input
          className={ `form__input ${error && 'form__input--error'
          } ${className}` }
          // type={type}
          type={ type === 'number' ? 'text' : type }
          placeholder={ placeholder }
          name={ name }
          value={ value === 0 || value === '0' ? '0' : value }
          defaultValue={ defaultValue }
          onChange={ handleOnChange }
          onBlur={ onBlur }
          disabled={ disabled }
          min={ min }
          max={ max }
          step={ isDecimal ? '0.01' : '1' } // Set step to '0.01' for decimal, '1' for non-decimal
          pattern={ isDecimal ? '^\\d*\\.?\\d*$' : '^[0-9]*$' } // Use regex pattern for validation
          title={ isDecimal ? 'Enter a decimal value' : 'Enter a whole number' }
        />
        {error && <span className="form__error">{error.message}</span>}
      </div>
    );

  case 'amount':
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}
        <input
          // type={type}
          type={ type === 'number' ? 'text' : type }
          value={ value === 0 || value === '0' ? '0' : value }
          name={ name }
          placeholder={ placeholder }
          className={ `form__input ${error && 'form__input--error'
          } ${className}` }
          onChange={ handleOnChange }
          onBlur={ onBlur }
          disabled={ disabled }
          step={ isDecimal ? '0.01' : '1' } // Set step to '0.01' for decimal, '1' for non-decimal
          pattern={ isDecimal ? '^\\d*\\.?\\d*$' : '^[0-9]*$' } // Use regex pattern for validation
          title={ isDecimal ? 'Enter a decimal value' : 'Enter a whole number' }
        />
        {error && <span className="form__error">{error.message}</span>}
      </div>
    );
  case 'date':
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}

        <input
          type={ type }
          value={ value }
          name={ name }
          placeholder={ placeholder }
          className={ `form__input ${error && 'form__input--error'
          } ${className}` }
          onChange={ handleOnChange }
          onBlur={ onBlur }
          disabled={ disabled }
          min={ min }
          max={ max }
        />
        {error && <span className="form__error">{error.message}</span>}
      </div>
    );
  default:
    return (
      <div className="form__group">
        {label && (
          <label htmlFor={ label } className="form__label">
            {label}
          </label>
        )}

        <input
          type={ type }
          value={ value }
          name={ name }
          placeholder={ placeholder }
          className={ `form__input ${error && 'form__input--error'
          } ${className}` }
          onChange={ handleOnChange }
          onBlur={ onBlur }
          disabled={ disabled }
        />
        {error && <span className="form__error">{error.message}</span>}
      </div>
    );
  }
}

InputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validators: PropTypes.arrayOf(PropTypes.shape),
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  isDecimal: PropTypes.bool,
};

InputField.defaultProps = {
  value: '',
  defaultValue: '',
  label: '',
  name: '',
  placeholder: '',
  validators: [],
  className: '',
  type: 'text',
  onChange: () => { },
  onBlur: () => { },
  disabled: false,
  min: null,
  max: null,
  isDecimal: true,
};

export default InputField;
