import React, {
  useEffect, useState, useCallback, Suspense, lazy
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import { ordersOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import {
  createOrder,
  editsOrder,
  getClients,
  getSectors,
} from '../../../data/redux/Orders/thunks';
import { selectedClient } from '../../../data/redux/Orders/orderSlice';
import Spinner from '../Spinner/Spinner';
import useOrdersValidator from '../../hooks/useOrdersValidator';
import getUsers from '../../../data/redux/users/thunks';
import getMeasurementUnit from '../../../data/redux/Units/thunks';
import calculateSectors from './utils/calculateSectors';
import calculateDefaultStartDate from './utils/calculateDefaultStartDate';
import calculateDefaultEffectiveDate from './utils/calculateDefaultEffectiveDate';
import clientSelected from './utils/clientSelected';
import calculateItemPrice from './utils/calculateItemPrice';
import { IS_QUOTE_REQUEST, QUOTE_REQUEST_CONFIRMATION } from './utils/constants';

const ClientSection = lazy(() => import('./components/ClientSection'));
const SectorList = lazy(() => import('./components/SectorList'));
const ImageGallery = lazy(() => import('./components/ImageGallery'));
const OrderInformation = lazy(() => import('./components/OrderInformation'));

function OrdersForm() {
  const { loading, clients, editOrders, sectors, client } = useSelector(
    (state) => state.order
  );

  const { uid, commissions } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());

    dispatch(getClients());
    dispatch(getMeasurementUnit());
  }, []);

  const [sectorList, setSectorList] = useState(
    editOrders
      ? editOrders?.sectors.map((sect, index) => ({
        idSector: index + 1,
        isNew: false,
      }))
      : [{
        idSector: 1, isNew: true
      }]
  );
  const [counter, setCounter] = useState(
    editOrders ? editOrders?.sectors.length : 1
  );

  const { formState, setFormState } = useForm({
    userId: editOrders ? editOrders?.user?.id : uid,
    clientId: editOrders ? editOrders?.client?.id : '',
    orderStatusId: editOrders ? editOrders?.order_status.id : 1,
    paymentStatusId: editOrders ? editOrders?.payment_status.id : 1,
    startDate: editOrders
      ? editOrders?.start_date.slice(0, 10)
      : calculateDefaultStartDate(),
    effectiveDate: editOrders
      ? editOrders?.effective_date.slice(0, 10)
      : calculateDefaultEffectiveDate(),
    specialComm: editOrders ? editOrders?.special_comm : false,
    isOpen: editOrders ? editOrders?.is_open : true,
    version: editOrders ? editOrders?.version : null,
    origin_order: editOrders ? editOrders?.origin_order : null,
    sectors: calculateSectors(editOrders),
    dollarBalance: editOrders ? editOrders?.dollar_balance : 0,
    pesoBalance: editOrders ? editOrders?.peso_balance : 0,
    dollarBonification: editOrders ? editOrders?.dollar_bonification : 0,
    pesoBonification: editOrders ? editOrders?.peso_bonification : 0,
    dollarCommission: editOrders ? editOrders?.dollar_commission : 0,
    pesoCommission: editOrders ? editOrders?.peso_commission : 0,
    images: editOrders ? editOrders?.images : [],
    observation: editOrders ? editOrders?.observation : '',
    place: editOrders ? editOrders?.place : '',
  });

  const { errors, validateForm, onBlurField } = useOrdersValidator(formState);

  const onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    if (name === 'clientId') {
      setFormState({
        ...formState, [name]: value, specialComm: false
      });
      dispatch(selectedClient(clientSelected(value, clients)));
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
    if (errors[name]?.dirty) {
      validateForm({
        form: nextFormState,
        field: name,
        errors,
      });
    }
  };

  useEffect(() => {
    dispatch(getSectors());
  }, []);

  const increment = useCallback(() => {
    setCounter((prevCounter) => prevCounter + 1);
    setSectorList((prevSectorList) => [
      ...prevSectorList,
      {
        idSector: counter + 1, isNew: true
      },
    ]);
  }, [counter, setCounter, setSectorList]);

  const decrement = useCallback(
    (id) => {
      setSectorList((prevSectorList) =>
        prevSectorList.filter((s) => s.idSector !== id)
      );
      setFormState((prevFormState) => ({
        ...prevFormState,
        sectors: prevFormState.sectors.filter((sect) => sect.idSector !== id),
      }));
    },
    [setSectorList, setFormState]
  );

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const formData = new FormData();
    formData.append('userId', formState.userId);
    formData.append('clientId', formState.clientId);
    formData.append('orderStatusId', formState.orderStatusId);
    formData.append('paymentStatusId', formState.paymentStatusId);
    formData.append('startDate', formState.startDate);
    formData.append('effectiveDate', formState.effectiveDate);
    formData.append('specialComm', formState.specialComm ? 1 : 0);
    formData.append('isOpen', formState.isOpen ? 1 : 0);
    formData.append('version', formState.version ? formState.version : 0);
    formData.append(
      'origin_order',
      formState.origin_order ? formState.origin_order : 0
    );
    formData.append('dollarBalance', formState.dollarBalance);
    formData.append('pesoBalance', formState.pesoBalance);
    formData.append('dollarBonification', formState.dollarBonification);
    formData.append('pesoBonification', formState.pesoBonification);
    formData.append('dollarCommission', formState.dollarCommission);
    formData.append('pesoCommission', formState.pesoCommission);
    formData.append(
      'observation',
      formState.observation ? formState.observation : ''
    );
    formData.append('place', formState.place);
    formData.append('quote_request', IS_QUOTE_REQUEST);
    formData.append('quote_request_status', QUOTE_REQUEST_CONFIRMATION);

    formState.images.forEach((image, index) => {
      if (image.path) {
        formData.append(`images[${index}]`, image.id);
      } else {
        formData.append(`images[${index}]`, image);
      }
    });

    formState.sectors.forEach((sector, sectorIndex) => {
      formData.append(`sectors[${sectorIndex}][sectorId]`, sector.sectorId);
      formData.append(`sectors[${sectorIndex}][id]`, sector.id);
      formData.append(`sectors[${sectorIndex}][facilities]`, sector.facilities);
      formData.append(
        `sectors[${sectorIndex}][observations]`,
        sector.observations ? sector.observations : ''
      );
      sector.items.forEach((item, itemIndex) => {
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][id]`,
          parseInt(sector.sectorId, 10)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][itemId]`,
          parseInt(item.itemId, 10)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][quantity]`,
          Number(item.quantity)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][width]`,
          parseFloat(item.width)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][height]`,
          parseFloat(item.height)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][depth]`,
          parseFloat(item.depth)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][observations]`,
          item.observations ? item.observations : ''
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][price]`,
          parseFloat(item.price)
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][currency]`,
          item.currency
        );
        formData.append(
          `sectors[${sectorIndex}][items][${itemIndex}][bonification]`,
          parseFloat(item.bonification)
        );
      });
    });

    if (editOrders) {
      dispatch(
        editsOrder({
          id: editOrders.id,
          _body: formData,
          isOpen: formState.isOpen,
        })
      );
    } else {
      dispatch(createOrder(formData));
    }
    setTimeout(() => {
      navigate('/orders');
    }, 1000);
    return null;
  };

  const clientsEnableds = clients?.filter((c) => c?.enabled === true);

  function calculateSubtotal(currency) {
    let subtotal = 0;
    let bonif = 0;
    let commission = 0;
    formState.sectors.forEach((sect) => {
      sect?.items?.forEach((it) => {
        if (it.detail?.currency?.id === currency) {
          const { itemPrice, itemBonification, itemCommission } =
            calculateItemPrice(it, formState, commissions, client);
          subtotal += itemPrice;
          bonif += itemBonification;
          commission += itemCommission;
        }
      });
      if (currency === 1) subtotal += sect.facilities;
    });
    return {
      subtotal, bonif, commission
    };
  }

  function subTotalPeso() {
    const { subtotal, bonif, commission } = calculateSubtotal(1);
    formState.pesoBalance = subtotal;
    formState.pesoBonification = bonif;
    formState.pesoCommission = commission;
    return subtotal;
  }

  function subTotalDollar() {
    const { subtotal, bonif, commission } = calculateSubtotal(2);
    formState.dollarBalance = subtotal;
    formState.dollarBonification = bonif;
    formState.dollarCommission = commission;
    return subtotal;
  }

  useEffect(() => {
    if (editOrders) {
      dispatch(selectedClient(clientSelected(editOrders?.client?.id, clients)));
    }
  }, []);

  const onChangeFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let allFilesValid = true;

    Array.from(files).forEach((file) => {
      if (
        !(
          file.name.endsWith('.jpg') ||
          file.name.endsWith('.png') ||
          file.name.endsWith('.webp')
        )
      ) {
        allFilesValid = false;
        toast.error(`El archivo "${file.name}" no tiene el formato válido`);
      }
    });

    const filesArray =
      formState.images?.length > 0
        ? [...formState.images, ...Array.from(files)]
        : Array.from(files);

    if (allFilesValid) {
      setFormState({
        ...formState,
        [name]: filesArray,
      });
      toast.success('Archivos cargados correctamente');
    }
  };

  return sectors.length > 0 && !loading ? (
    <div className="container-orders">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      <div className="div-btn orders-btn">
        <button onClick={ handleClose } className="btn-close" type="button">
          <span className="material-icons-outlined">close</span>
        </button>
      </div>
      <form className="form-orders" action="" onSubmit={ handleFormSubmit }>
        <Suspense fallback={ <Spinner /> }>
          <ClientSection
            formState={ formState }
            clientsEnableds={ clientsEnableds }
            errors={ errors }
            editOrders={ editOrders }
            setFormState={ setFormState }
            onBlurField={ onBlurField }
            onInputChange={ onInputChange }
            clientSelected={ clientSelected }
            onChangeFile={ onChangeFile }
          />
        </Suspense>

        {clientSelected(formState.clientId, clients)?.client_type?.id !== 0 ? (
          <div className="container-client-form container-form">
            <Suspense fallback={ <Spinner /> }>
              <SectorList
                sectorList={ sectorList }
                decrement={ decrement }
                increment={ increment }
                formState={ formState }
                setFormState={ setFormState }
              />
            </Suspense>

            <Suspense fallback={ <Spinner /> }>
              <ImageGallery formState={ formState } setFormState={ setFormState } />
            </Suspense>

            <Suspense fallback={ <Spinner /> }>
              <OrderInformation
                onInputChange={ onInputChange }
                formState={ formState }
                errors={ errors }
              />
            </Suspense>

            <div className="align-buttons">
              <button className="btn-save btn-flex" type="submit">
                <span className="material-icons-outlined">save</span>
              </button>
            </div>

            <div className="orders-main-total-subtotal">
              <div className="orders-total">
                <InputField
                  label="SUBTOTAL (USD)"
                  id="subtotalDollar"
                  name="subtotalDollar"
                  value={ subTotalDollar().toFixed(2) }
                  type="numbers"
                  className="input-pc"
                />
              </div>
              <div className="orders-total">
                <InputField
                  label="SUBTOTAL (ARS)"
                  id="subtotalPeso"
                  name="subtotalPeso"
                  value={ subTotalPeso().toFixed(2) }
                  type="numbers"
                  className="input-pc"
                />
              </div>
            </div>
          </div>
        ) : null}
      </form>
    </div>
  ) : (
    <Spinner />
  );
}

export default OrdersForm;
