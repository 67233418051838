import React from 'react';
import Spinner from '../Spinner/Spinner';

function InnerSpinner() {
  return (
    <div className="inner-spinner">
      <Spinner />
    </div>
  );
}

export default InnerSpinner;
