import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import { unitsOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';

import {
  createMeasurement,
  editsMeasurement,
  getUnitTypes,
} from '../../../data/redux/Units/thunks';
import useUnitsValidator from '../../hooks/useUnitsValidator';
import Spinner from '../Spinner/Spinner';

function UnitsForm() {
  const { editUnit } = useSelector((state) => state.measurementunit);

  const { formState, setFormState } = useForm({
    id: editUnit ? editUnit.id : '',
    measurementUnit: editUnit ? editUnit.measurement_unit : '',
    unitTypeId: editUnit ? editUnit.unit_type.id : '',
    nomenclature: editUnit ? editUnit.nomenclature : '',
    description: editUnit ? editUnit.description : '',
    isDecimal: editUnit ? Boolean(editUnit.is_decimal) : false,
  });

  const { errors, validateForm, onBlurField } = useUnitsValidator(formState);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();

  const { unitTypes, loading } = useSelector((state) => state.measurementunit);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    unitTypes?.length === 0 && dispatch(getUnitTypes());
  }, []);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    let parsedValue = value;
    if (name === 'isDecimal') {
      parsedValue = value === 'true' && true;
    }
    const nextFormState = {
      ...formState,
      [name]: parsedValue,
    };
    setFormState({
      ...formState,
      [name]: parsedValue,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const newForm = {
      id: formState.id,
      measurement_unit: formState.measurementUnit,
      unit_type_id: Number(formState.unitTypeId),
      nomenclature: formState.nomenclature,
      description: formState.description,
      is_decimal: Boolean(formState.isDecimal),
    };

    if (editUnit) {
      dispatch(editsMeasurement({
        id: formState.id, _body: newForm
      }));
      navigate('/units');
    } else {
      dispatch(createMeasurement(newForm));
      navigate('/units');
    }
    return null;
  };

  return !loading ? (
    <div className="container-main-unit-form">
      <NavSec title="UNIDADES" content={ unitsOptions } />
      <div className="container-client-form container-form unit-form">
        <form className="form-base" action="" onSubmit={ handleFormSubmit }>
          <div className="div-btn">
            <button onClick={ handleClick } className="btn-close" type="button">
              <span className="material-icons-outlined">close</span>
            </button>
          </div>
          <div className="container-colum-form">
            <div className="mini-container-form">
              <div className="ctn-input">
                <InputField
                  label="* UNIDAD DE MEDIDA"
                  name="measurementUnit"
                  value={ formState.measurementUnit }
                  type="text"
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={
                    errors.measurementUnit.error ? 'red-advertise' : ''
                  }
                />
              </div>
              <div className="container-client-form container-form">
                {errors.measurementUnit.dirty &&
                  errors.measurementUnit.error ? (
                    <p className="error-validation">
                      {errors.measurementUnit.message}
                    </p>
                  ) : null}
              </div>
              <div className="select-input">
                <div className="container-select-input">
                  <label className="label-select" htmlFor="as">
                    <div className="text-label">* TIPO DE UNIDAD</div>
                    <select
                      name="unitTypeId"
                      value={ formState.unitTypeId }
                      type="text"
                      id="tipo"
                      onBlur={ onBlurField }
                      onChange={ onInputChange }
                      className={
                        errors.unitTypeId.error
                          ? 'select-opt red-advertise'
                          : 'select-opt'
                      }
                    >
                      <option
                        className="option-select"
                        disabled
                        default
                        value=""
                      >
                        Seleccione
                      </option>
                      {unitTypes.map((item) => (
                        <option
                          className="option-select"
                          key={ `${item.id}` }
                          value={ `${item.id}` }
                        >
                          {item.unit_type}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="container-client-form container-form">
                {errors.unitTypeId.dirty && errors.unitTypeId.error ? (
                  <p className="error-validation">
                    {errors.unitTypeId.message}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="mini-container-form">
              <div className="ctn-input">
                <InputField
                  label="* NOMENCLATURA"
                  name="nomenclature"
                  type="text"
                  value={ formState.nomenclature }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors.nomenclature.error ? 'red-advertise' : '' }
                />
              </div>
              <div className="container-client-form container-form">
                {errors.nomenclature.dirty && errors.nomenclature.error ? (
                  <p className="error-validation">
                    {errors.nomenclature.message}
                  </p>
                ) : null}
              </div>
              <div className="ctn-input unit-textArea">
                <InputField
                  label="DESCRIPCIÓN"
                  name="description"
                  value={ formState.description }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                />
              </div>
            </div>
          </div>
          <div className="units-input-detail units-form-checkbox">
            <label htmlFor="a">
              ENTERO:
              <input
                id="entero"
                name="isDecimal"
                className="inp-units"
                type="radio"
                defaultChecked={ !formState.isDecimal }
                value="false"
                onBlur={ onBlurField }
                onChange={ onInputChange }
              />
            </label>
            <label htmlFor="a">
              DECIMAL:
              <input
                id="decimal"
                name="isDecimal"
                className="inp-units"
                type="radio"
                defaultChecked={ formState.isDecimal }
                value="true"
                onBlur={ onBlurField }
                onChange={ onInputChange }
              />
            </label>
          </div>
          <button className="btn-save" type="submit">
            <span className="material-icons-outlined">save</span>
          </button>
        </form>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default UnitsForm;
