import { useState } from 'react';
import {
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useUnitsValidator = (form) => {
  const [errors, setErrors] = useState({
    measurementUnit: {
      dirty: false,
      error: false,
      message: '',
    },
    unitTypeId: {
      dirty: false,
      error: false,
      message: '',
    },
    nomenclature: {
      dirty: false,
      error: false,
      message: '',
    },
    description: {
      dirty: false,
      error: false,
      message: '',
    },
    isDecimal: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { nomenclature, measurementUnit, unitTypeId } = form;

    if (
      nextErrors.measurementUnit.dirty &&
      (field ? field === 'measurementUnit' : true)
    ) {
      const measurementUnitMessage = textValidator(measurementUnit, form);
      nextErrors.measurementUnit.error = !!measurementUnitMessage;
      nextErrors.measurementUnit.message = measurementUnitMessage;
      if (measurementUnitMessage) isValid = false;
    }

    if (
      nextErrors.unitTypeId.dirty &&
      (field ? field === 'unitTypeId' : true)
    ) {
      const unitTypeIdMessage = selectValidator(unitTypeId);
      nextErrors.unitTypeId.error = !!unitTypeIdMessage;
      nextErrors.unitTypeId.message = unitTypeIdMessage;
      if (unitTypeIdMessage) isValid = false;
    }

    if (
      nextErrors.nomenclature.dirty &&
      (field ? field === 'nomenclature' : true)
    ) {
      const nomenclatureMessage = textValidator(nomenclature);
      nextErrors.nomenclature.error = !!nomenclatureMessage;
      nextErrors.nomenclature.message = nomenclatureMessage;
      if (nomenclatureMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};

export default useUnitsValidator;
