import { useState } from 'react';
import {
  clientCommValidator,
  cuitValidator,
  // emailValidator,
  numberValidator,
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useClientsValidator = (form) => {
  const [errors, setErrors] = useState({
    clientType: {
      dirty: false,
      error: false,
      message: '',
    },
    fullname: {
      dirty: false,
      error: false,
      message: '',
    },
    cuit: {
      dirty: false,
      error: false,
      message: '',
    },
    address: {
      dirty: false,
      error: false,
      message: '',
    },
    phone: {
      dirty: false,
      error: false,
      message: '',
    },
    // email: {
    //   dirty: false,
    //   error: false,
    //   message: '',
    // },
    company: {
      dirty: false,
      error: false,
      message: '',
    },
    observations: {
      dirty: false,
      error: false,
      message: '',
    },
    bonification: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
      clientType,
      fullname,
      cuit,
      // address,
      phone,
      // email,
      company,
      bonification,
      // observations,
      // bonification,
    } = form;

    if (
      nextErrors.clientType.dirty &&
      (field ? field === 'clientType' : true)
    ) {
      const clientTypeMessage = selectValidator(clientType);
      nextErrors.clientType.error = !!clientTypeMessage;
      nextErrors.clientType.message = clientTypeMessage;
      if (clientTypeMessage) isValid = false;
    }
    if (nextErrors.fullname.dirty && (field ? field === 'fullname' : true)) {
      const fullnameMessage = textValidator(fullname);
      nextErrors.fullname.error = !!fullnameMessage;
      nextErrors.fullname.message = fullnameMessage;
      if (fullnameMessage) isValid = false;
    }
    if (nextErrors.cuit.dirty && (field ? field === 'cuit' : true)) {
      const cuitMessage = cuitValidator(cuit);
      if (cuitMessage !== 'El campo no puede estar vacío') {
        nextErrors.cuit.error = !!cuitMessage;
        nextErrors.cuit.message = cuitMessage;
      } else {
        nextErrors.cuit.error = false;
      }
      if (cuitMessage && cuitMessage !== 'El campo no puede estar vacío') {
        isValid = false;
      }
    }
    // if (nextErrors.address.dirty && (field ? field === 'address' : true)) {
    //   const addressMessage = selectValidator(address)
    //   nextErrors.address.error = !!addressMessage
    //   nextErrors.address.message = addressMessage
    //   if (addressMessage) isValid = false
    // }
    if (nextErrors.phone.dirty && (field ? field === 'phone' : true)) {
      const phoneMessage = numberValidator(phone);
      nextErrors.phone.error = !!phoneMessage;
      nextErrors.phone.message = phoneMessage;
      if (phoneMessage) isValid = false;
    }
    // if (nextErrors.email.dirty && (field ? field === 'email' : true)) {
    //   const emailMessage = emailValidator(email)
    //   nextErrors.email.error = !!emailMessage
    //   nextErrors.email.message = emailMessage
    //   if (emailMessage) isValid = false
    // }
    if (
      nextErrors.company.dirty &&
      (field ? field === 'company' : true) &&
      clientType === '2'
    ) {
      const companyMessage = textValidator(company);
      nextErrors.company.error = !!companyMessage;
      nextErrors.company.message = companyMessage;
      if (companyMessage) isValid = false;
    }
    if (
      nextErrors.bonification.dirty &&
      (field ? field === 'bonification' : true)
    ) {
      const commPercentClientMessage = clientCommValidator(bonification);
      nextErrors.bonification.error = !!commPercentClientMessage;
      nextErrors.bonification.message = commPercentClientMessage;
      if (commPercentClientMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};

export default useClientsValidator;
