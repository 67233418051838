import React from 'react';
import InputField from '../InputField/InputField';
import { rolesOptions } from '../../../helpers/dataOptions';
import useUserFormLogic from './hooks/useUserFormLogic';

function UserFormData() {
  const {
    formState,
    errors,
    onInputChange,
    handleEnableChange,
    onBlurField,
    handleFormSubmit,
    handleClick,
  } = useUserFormLogic();

  return (
    <form
      className="form-base form-user container-client-form"
      action=""
      onSubmit={ handleFormSubmit }
    >
      <div className="div-btn">
        <button onClick={ handleClick } className="btn-close" type="button">
          <span className="material-icons-outlined">close</span>
        </button>
      </div>
      <div className="container-colum-form">
        <div className="mini-container-form">
          <div className="ctn-input">
            <InputField
              label="*NOMBRE Y APELLIDO"
              name="fullName"
              id="fullName"
              value={ formState.fullName }
              type="text"
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={ errors.fullName.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.fullName.dirty && errors.fullName.error ? (
              <p className="error-validation">{errors.fullName.message}</p>
            ) : null}
          </div>
          <div className="ctn-input">
            <InputField
              label="*CUIT/CUIL/DNI"
              name="CUIT"
              id="CUIT"
              type="number"
              value={ formState.CUIT }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              isDecimal={ false }
              className={ errors.CUIT.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.CUIT.dirty && errors.CUIT.error ? (
              <p className="error-validation">{errors.CUIT.message}</p>
            ) : null}
          </div>
          <div className="ctn-input">
            <InputField
              label="*EMAIL"
              name="email"
              id="email"
              type="text"
              value={ formState.email }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={ errors.email.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.email.dirty && errors.email.error ? (
              <p className="error-validation">{errors.email.message}</p>
            ) : null}
          </div>
        </div>
        <div className="mini-container-form">
          <div className="ctn-input">
            <InputField
              label="*DIRECCIÓN"
              name="address"
              id="address"
              type="text"
              value={ formState.address }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={ errors.address.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.address.dirty && errors.address.error ? (
              <p className="error-validation">{errors.address.message}</p>
            ) : null}
          </div>
          <div className="ctn-input">
            <InputField
              label="*TELÉFONO"
              name="phone"
              id="phone"
              type="number"
              value={ formState.phone }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={ errors.phone.error ? 'red-advertise' : '' }
              min={ 0 }
              isDecimal={ false }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.phone.dirty && errors.phone.error ? (
              <p className="error-validation">{errors.phone.message}</p>
            ) : null}
          </div>
          {/* <div className="ctn-input">
        <InputField
          label="ROL"
          name="roles"
          id="roles"
          type="text"
          value="ADMIN"
          onBlur={onBlurField}
          onChange={onInputChange}
        />
      </div> */}
          <div className="select-input">
            <label className="label-select" htmlFor="as">
              {' '}
              <div className="text-label">*ROL</div>
              <select
                // className="select-opt"
                className={
                  errors.roles.error ? 'select-opt red-advertise' : 'select-opt'
                }
                name="roles"
                id="roles"
                type="text"
                value={ formState.roles }
                // onClick={() =>
                //   setFormState({
                //     ...formState,
                //     roles: '',
                //   })
                // }
                onBlur={ onBlurField }
                onChange={ onInputChange }
              >
                <option className="option-select" value="">
                  Seleccione un rol
                </option>
                {rolesOptions.map((item) => (
                  <option
                    className="option-select"
                    key={ item.id }
                    value={ item.name }
                  >
                    {item.displayName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="container-client-form container-form">
            {errors.roles.dirty && errors.roles.error ? (
              <p className="error-validation">{errors.roles.message}</p>
            ) : null}
          </div>
          {/* <div className="ctn-input">
  <InputField
    label="COMISIÓN"
    name="comision"
    type="text"
    value={formState.comision}
    onChange={onInputChange}
  />
</div> */}
        </div>
      </div>
      <div className="container-user-habilitado">
        <label className="ctn-check" htmlFor="a">
          HABILITADO
          <input
            id="enabled"
            name="enabled"
            className="inp-check"
            type="checkbox"
            checked={ formState.enabled }
            onChange={ handleEnableChange }
          />
        </label>
      </div>
      <button className="btn-save" type="submit">
        <span className="material-icons-outlined">save</span>
      </button>
    </form>
  );
}

export default UserFormData;
