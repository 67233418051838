import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import useOrders from './useOrders';
import ButtonArrowClientOrders from '../components/ButtonArrowClientOrders';

function useOrdersColumns() {
  const { orders, handleConfirmCloseOrder } = useOrders();
  const { permissions } = useSelector((state) => state.auth);

  const handleCloseOrder = async (orderEdit) => {
    if (orderEdit.is_open === false) {
      return;
    }
    const toastId = toast(
      <div className="flex-column">
        <article className="close-order-confirm">
          <button
            type="button"
            className="btn-close mb-4"
            onClick={ () => toast.remove() }
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </article>
        <div className="text-center">¿Estás seguro de cerrar el pedido?</div>
        <br />
        <div className="order-confirm">
          <button
            type="button"
            className=""
            onClick={ () => {
              handleConfirmCloseOrder(orderEdit);
            } }
          >
            Si
          </button>
          <button
            type="button"
            className=""
            onClick={ () => toast.dismiss(toastId) }
          >
            No
          </button>
        </div>
      </div>,
      {
        duration: 20000,
      }
    );
  };

  const columnsTableClientOrder = [
    {
      name: 'N° PEDIDO',
      selector: (row) => row.origin_order,
      sortable: true,
      width: '40%',
    },
    {
      name: 'SECTORES',
      selector: (row) => `${row.sectors.length} Sectores`,
      sortable: true,
      width: '45%',
    },
    {
      name: '',
      cell: (row) => (
        <div className={ `${row.is_open ? 'button1' : 'button2'}` }>
          {permissions.includes('order-create') ? (
            <button type="button" onClick={ () => handleCloseOrder(row) }>
              .
            </button>
          ) : (
            <button type="button">.</button>
          )}
        </div>
      ),
      sortable: true,
      button: true,
      width: '5%',
    },
    {
      name: '',
      cell: (row) => ButtonArrowClientOrders(row),
      sortable: true,
      button: true,
      width: '10%',
    },
  ];
  return {
    columnsTableClientOrder, orders
  };
}

export default useOrdersColumns;
