/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getMeasurementUnit from './thunks';

export const measurementUnitSlice = createSlice({
  name: 'measurementunit',
  initialState: {
    measurementunit: [],
    unitTypes: [],
    editUnit: null,
    loading: true,
  },

  reducers: {
    addMeasurementUnit: (state, { payload }) => {
      state.measurementunit = [...state.measurementunit, {
        ...payload
      }];
      state.editUnit = null;
    },

    editMeasurementUnit: (state, { payload }) => {
      state.measurementunit = state.measurementunit.map((unit) =>
        unit.id === payload.id ? payload : unit
      );
      state.editUnit = null;
    },

    deleteMeasurementUnit: (state, { payload }) => {
      state.measurementunit = state.measurementunit.filter(
        (unit) => unit.id !== payload
      );
      state.loading = false;
    },

    selectMeasurementUnit: (state, { payload }) => {
      state.editUnit = state.measurementunit.find(
        (unit) => unit.id === payload.id
      );
    },

    cleanMeasurementUnit: (state) => {
      state.measurementunit = [];
      state.editUnit = null;
      state.loading = false;
    },

    onLoadingMeasurementUnit: (state) => {
      state.loading = true;
    },

    editUnit: (state, { payload }) => {
      state.measurementunit = payload;
    },

    selectUnitTypes: (state, { payload }) => {
      state.unitTypes = payload.unit_types;
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeasurementUnit.fulfilled, (state, { payload }) => {
      state.measurementunit = payload;
      state.editUnit = null;
      state.loading = false;
    });
    builder.addCase(getMeasurementUnit.rejected, (state) => {
      state.measurementunit = 'fail';
      state.editUnit = null;
    });
  },
});

export const {
  addMeasurementUnit,
  editMeasurementUnit,
  deleteMeasurementUnit,
  selectMeasurementUnit,
  onLoadingMeasurementUnit,
  editUnit,
  selectUnitTypes,
  cleanMeasurementUnit,
  setLoading,
} = measurementUnitSlice.actions;

export const measurementUnitReducer = measurementUnitSlice.reducer;
