import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function DetailsTableDesktop({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/client/orders/${data.id}`);
  };

  return (
    <div className="container-detail-main">
      <div className="detail-data">
        <div className="info-a">
          <p className="p-title-detail">NOMBRE Y APELLIDO:</p>
          <p className="p-Details">{data.fullname ? data.fullname : '--'}</p>
          {data?.client_type?.client_type !== 'Final' && (
            <>
              <p className="p-title-detail">EMPRESA:</p>
              <p className="p-Details">{data.company ? data.company : '--'}</p>
            </>
          )}
          <p className="p-title-detail">CUIT/CUIL/DNI:</p>
          <p className="p-Details">{data.cuit ? data.cuit : '--'}</p>
        </div>
        <div className="info-b">
          <p className="p-title-detail">DIRECCIÓN:</p>
          <p className="p-Details">{data.address ? data.address : '--'}</p>
          <p className="p-title-detail">TELÉFONO:</p>
          <p className="p-Details">{data.phone ? data.phone : '--'}</p>
        </div>
        <div className="info-c">
          <p className="p-title-detail">N° DE CLIENTE:</p>
          <p className="p-Details">{data.id ? data.id : '--'}</p>
          {data?.client_type?.client_type !== 'Final' && (
            <>
              <p className="p-title-detail">COMISIÓN:</p>
              <p className="p-Details">
                {data?.commissions?.length > 0
                  ? data?.commissions[0]?.commission
                  : '0'}{' '}
                %
              </p>
            </>
          )}
        </div>
      </div>
      <div className="container-btn-shifts">
        <div className="btn-shifts">
          <p className="p-title-shifts">
            {data?.orders?.length
              ? `${data.orders[0].orders_close} PEDIDOS CERRADOS`
              : '0 PEDIDOS CERRADOS'}
          </p>
          <p className="p-title-shifts">
            {data?.orders?.length
              ? `${data.orders[0].orders_open} PEDIDOS ABIERTOS`
              : '0 PEDIDOS ABIERTOS'}
          </p>
        </div>
        <div className="btn-Main">
          <button type="button" onClick={ handleClick }>
            <span className="material-icons-outlined">list_alt</span>
          </button>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}
DetailsTableDesktop.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullname: PropTypes.string.isRequired,
    cuit: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    company: PropTypes.string,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    bonification: PropTypes.number.isRequired,
    observations: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    client_type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      client_type: PropTypes.string.isRequired,
    }).isRequired,
    commissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        commission: PropTypes.number,
      })
    ),
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        orders_close: PropTypes.number.isRequired,
        orders_open: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
};

export default DetailsTableDesktop;
