import ButtonArticleMod from '../components/ButtonItemsMod';

const columnsTableDesktop = [
  {
    name: 'CÓDIGO',
    selector: (row) => `${row?.nomenclature?.nomenclature}-${row?.id}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'ARTÍCULOS',
    selector: (row) => row?.item,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'PRECIO',
    selector: (row) => `${row?.currency?.currency} ${row?.price}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'ESTADO',
    selector: (row) => `${row?.enabled ? 'Habilitado' : 'Deshabilitado'}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: '',
    cell: (row) => ButtonArticleMod(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
