import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sectionsOptions } from '../../../helpers/dataOptions';

import getOrdersStatuses from '../../../data/redux/OrderStatuses/thunks';

function HomeComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { displayName, permissions, loading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(getOrdersStatuses());
  }, []);

  const handleClick = (path) => {
    navigate(path);
  };

  return !loading ? (
    <div className="container-main-home">
      <div className="hello-Us">
        <h1>Bienvenidos</h1>
        <p>{displayName}</p>
      </div>
      <div className="col-button">
        {sectionsOptions.map((section) =>
          permissions.includes(section.permission) ? (
            <button
              key={ section.path }
              onClick={ () => handleClick(section.path) }
              type="button"
              className={
                permissions.includes(section.permission)
                  ? 'bnt-home'
                  : 'bnt-home-out'
              }
            >
              <div className="container-card-home-description">
                <h2 className="title-button-home">{section.section}</h2>
                <img
                  className="img-home-description"
                  src={ section.image }
                  alt=""
                />
                <span className="material-icons-round">arrow_forward_ios</span>
                <div className="description-card">
                  <h2 className="title-button-home-card">{section.section}</h2>
                  <p className="text-description-home">{section.description}</p>
                </div>
              </div>
            </button>
          ) : (
            <div key={ section.path } className="bnt-home-out">
              <div className="container-card-home-description">
                <h2 className="title-button-home">{section.section}</h2>
                <img
                  className="img-home-description"
                  src={ section.image }
                  alt=""
                />
                <span className="material-icons-round">arrow_forward_ios</span>
                <div className="description-card">
                  <h2 className="title-button-home-card">{section.section}</h2>
                  <p className="text-description-home">{section.description}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  ) : (
    <>Loading...</>
  );
}

export default HomeComponent;
