import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

// eslint-disable-next-line import/no-cycle
import { changeLoading, selectOrderStatuses } from './orderStatusesSlice';

const getOrdersStatuses = createAsyncThunk('orders', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/order_statuses`
    );
    return data?.order_statuses;
  } catch (error) {
    toast(error);
    return [];
  }
});

export default getOrdersStatuses;

export const getOrderStatuses = () => async (dispatch) => {
  dispatch(changeLoading(true));

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/order_statuses`,
      config()
    );
    dispatch(selectOrderStatuses(data?.order_statuses));
    dispatch(changeLoading(false));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};
