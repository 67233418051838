import useQuotesDesktop from '../hooks/useQuotesDesktop';
import ButtonQuotesMod from './ButtonQuotesMod';

function QuotesColumnsDesktop() {
  const { quotes } = useQuotesDesktop();

  const totalItems = (row) => {
    let total = 0;
    row.sectors.forEach(element => {
      total += element.item_list.length;
    });
    return total;
  };

  const columnsTableDesktop = [
    {
      name: 'N° C',
      selector: (row) => row?.origin_order,
      sortable: true,
      width: '5.5%',
    },
    {
      name: 'CONTACTO',
      selector: (row) =>
        row?.client.fullname,
      sortable: true,
      width: '22%',
    },
    {
      name: 'FECHA DE SOLICITUD',
      selector: (row) =>
        row?.start_date.slice(0, 10),
      sortable: true,
      width: '22%',
    },
    {
      name: 'SECTORES',
      selector: (row) =>
        `${row?.sectors?.length} Sector(es)`,
      sortable: true,
      width: '22%',
    },
    {
      name: 'ARTICULOS',
      selector: (row) => `${totalItems(row)} Artículo(s)`,
      sortable: true,
      width: '22%',
    },
    {
      name: '',
      cell: () => (
        null
      ),
      sortable: true,
      button: true,
      width: '2%',
    },
    {
      name: '',
      cell: (row) => ButtonQuotesMod(row),
      sortable: true,
      button: true,
      width: '2%',
    },
  ];
  return {
    columnsTableDesktop, quotes
  };
}

export default QuotesColumnsDesktop;
