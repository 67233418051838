import ButtonClientsModMobile from '../components/ButtonClientsModMobile';
import { CLIENT_FINAL_ID } from '../../../../helpers/rolesConstants';

const columnsTableMobile = [
  {
    name: 'CLIENTE',
    selector: (row) =>
      row?.client_type?.id === CLIENT_FINAL_ID
        ? `${row?.id} - ${row?.fullname}`
        : `${row?.id} - ${row?.company} | ${row?.fullname}`,
    sortable: true,
    minWidth: '42%',
  },
  {
    name: 'SALDO',
    selector: (row) =>
      `${row?.orders?.length
        ? `US$ ${row?.orders?.[0]?.total_dollar_balance} - $ ${row?.orders?.[0]?.total_peso_balance}`
        : 0
      }`,
    sortable: true,
    minWidth: '42%',
  },
  {
    name: '',
    cell: (row) => ButtonClientsModMobile(row),
    sortable: true,
    button: true,
    minWidth: '10%',
  },
];

export default columnsTableMobile;
