import React from 'react';

import PropTypes from 'prop-types';

function DetailsTableDesktop({ data }) {
  return (
    <div className="container-detail-main">
      <div className="container-detail-art">
        <div className="container-detail-art-cost">
          <div className="detail-data-art">
            <h3 className="p-title-detail">Tipo de artículo:</h3>
            <p className="p-Details">
              {data?.nomenclature?.item_type?.item_type}
            </p>
            <h3 className="p-title-detail">Moneda:</h3>
            <p className="p-Details">{data?.currency?.currency}</p>
            <h3 className="p-title-detail">Unidad de medida:</h3>
            <p className="p-Details">
              {data?.measurement_unit?.measurement_unit}
            </p>
          </div>
          <div className="p-detail-main-cost-unit">
            <div className="detail-data-art">
              <h3 className="p-title-detail">Costo:</h3>
              <p className="p-Details">{data?.cost}</p>
            </div>
            <div className="detail-data-art">
              {data?.nomenclature?.item_type?.item_type === 'Producto' ||
                data?.nomenclature?.item_type?.item_type === 'Material' ? (
                  <>
                    <h3>Dimensiones:</h3>
                    <p>
                    Altura: {data?.height}{' '}
                      {data?.measurement_unit?.nomenclature} - Ancho:{' '}
                      {data?.width} {data?.measurement_unit?.nomenclature} -
                    Profundidad:
                      {data?.depth} {data?.measurement_unit?.nomenclature}
                    </p>
                  </>
                ) : null}
            </div>
          </div>
        </div>
        <div
          className={
            data?.description ? 'detail-data-art-description' : 'no-description'
          }
        >
          <p>{data?.description}</p>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTableDesktop.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    item: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    depth: PropTypes.number,
    price: PropTypes.number,
    cost: PropTypes.number,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    nomenclature: PropTypes.shape({
      id: PropTypes.number.isRequired,
      nomenclature: PropTypes.string.isRequired,
      bonusable: PropTypes.bool.isRequired,
      item_type: PropTypes.shape({
        id: PropTypes.number.isRequired,
        item_type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    measurement_unit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      measurement_unit: PropTypes.string.isRequired,
      nomenclature: PropTypes.string.isRequired,
      description: PropTypes.string,
      is_decimal: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DetailsTableDesktop;
