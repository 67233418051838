import { useState } from 'react';
import {
  selectDateValidator,
  // selectValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useReportValidator = (form) => {
  const [errors, setErrors] = useState({
    fromDate: {
      dirty: false,
      error: false,
      message: '',
    },
    period: {
      dirty: false,
      error: false,
      message: '',
    },
    toDate: {
      dirty: false,
      error: false,
      message: '',
    },
    comparison: {
      dirty: false,
      error: false,
      message: '',
    },
    quote: {
      dirty: false,
      error: false,
      message: '',
    },
    userId: {
      dirty: false,
      error: false,
      message: '',
    },
    rolId: {
      dirty: false,
      error: false,
      message: '',
    },
    clientId: {
      dirty: false,
      error: false,
      message: '',
    },
    clientTypeId: {
      dirty: false,
      error: false,
      message: '',
    },
    isOpen: {
      dirty: false,
      error: false,
      message: '',
    },
    commPaid: {
      dirty: false,
      error: false,
      message: '',
    },
    orderStatusId: {
      dirty: false,
      error: false,
      message: '',
    },
    paymentStatusId: {
      dirty: false,
      error: false,
      message: '',
    },
    itemId: {
      dirty: false,
      error: false,
      message: '',
    },
    currencyId: {
      dirty: false,
      error: false,
      message: '',
    },
    unitId: {
      dirty: false,
      error: false,
      message: '',
    },
    nameReport: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
      fromDate,
      // period,
      // toDate,
      // comparison,
      // userId,
      // clientId,
      // isOpen,
      // orderStatusId,
      // paymentStatusId,
      // itemId,
      // currencyId,
      // unitId,
      // nameReport,
    } = form;

    if (nextErrors.fromDate.dirty && (field ? field === 'fromDate' : true)) {
      const fromDateMessage = selectDateValidator(fromDate);
      nextErrors.fromDate.error = !!fromDateMessage;
      nextErrors.fromDate.message = fromDateMessage;
      if (fromDateMessage) isValid = false;
    }
    // if (nextErrors.period.dirty && (field ? field === 'period' : true)) {
    //   const periodMessage = selectValidator(period)
    //   nextErrors.period.error = !!periodMessage
    //   nextErrors.period.message = periodMessage
    //   if (periodMessage) isValid = false
    // }
    // if (nextErrors.toDate.dirty && (field ? field === 'toDate' : true)) {
    //   const toDateMessage = selectDateValidator(toDate)
    //   nextErrors.toDate.error = !!toDateMessage
    //   nextErrors.toDate.message = toDateMessage
    //   if (toDateMessage) isValid = false
    // }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};

export default useReportValidator;
