import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import useElementHider from '../../../hooks/useElementHider';

import { acceptQuote, deleteQuote } from '../../../../data/redux/Quotes/thunks';

function ButtonQuotesMod(row) {
  const [toggle, setToggle] = useState(false);
  const deleteModifyButtonsRef = useElementHider(setToggle);
  const { id } = row;
  const { permissions } = useSelector((state) => state.auth);

  function mod() {
    setToggle(!toggle);
  }

  const dispatch = useDispatch();

  const handleAccept = () => {
    const toastId = toast(
      <div className="flex-column">
        <article className="close-order-confirm">
          <button
            type="button"
            className="btn-close mb-4"
            onClick={ () => toast.remove() }
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </article>
        <div className="text-center">¿Estás seguro de aceptar la cotización?</div>
        <br />
        <div className="order-confirm">
          <button
            type="button"
            className=""
            onClick={ () => {
              dispatch(acceptQuote(id));
              toast.dismiss(toastId);
            } }
          >
            Si
          </button>
          <button
            type="button"
            className=""
            onClick={ () => toast.dismiss(toastId) }
          >
            No
          </button>
        </div>
      </div>,
      {
        duration: 20000,
      }
    );
  };

  const handleDelete = async () => {
    const toastId = toast(
      <div className="flex-column">
        <article className="close-order-confirm">
          <button
            type="button"
            className="btn-close mb-4"
            onClick={ () => toast.remove() }
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </article>
        <div className="text-center">¿Estás seguro de eliminar la cotización?</div>
        <br />
        <div className="order-confirm">
          <button
            type="button"
            className=""
            onClick={ () => {
              dispatch(deleteQuote(id));
              toast.dismiss(toastId);
            } }
          >
            Si
          </button>
          <button
            type="button"
            className=""
            onClick={ () => toast.dismiss(toastId) }
          >
            No
          </button>
        </div>
      </div>,
      {
        duration: 20000,
      }
    );
  };

  return (
    <div ref={ deleteModifyButtonsRef }>
      { permissions.includes('order-delete') ||
        permissions.includes('order-update') ? (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modalEditarRol"
              onClick={ mod }
            >
              <span>&#10247;</span>
            </button>
          </div>
        ) : null }
      { toggle === true ? (
        <div className="container-button-mod">
          { permissions.includes('order-delete-open') ||
            permissions.includes('order-delete-close') ? (
              <button type="button" onClick={ handleDelete }>
                <span className="material-icons-outlined">delete</span>
              </button>
            ) : null }
          { permissions.includes('order-update') ? (
            <button type="button" onClick={ handleAccept }>
              <span className="material-icons-outlined">done</span>
            </button>
          ) : null }
        </div>
      ) : null }
    </div>
  );
}

export default ButtonQuotesMod;
