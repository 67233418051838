import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useForm from '../../../hooks/useForm';
import useUsersValidator from '../../../hooks/useUsersValidator';
import generateColor from '../utils/colorUtils';
import { createUser, editsUser } from '../../../../data/redux/users/thunks';

function useUserFormLogic() {
  const { editUser } = useSelector((state) => state.user);

  const { formState, setFormState, handleEnableChange } = useForm({
    id: editUser ? editUser.id : '',
    fullName: editUser ? editUser.fullName : '',
    CUIT: editUser ? editUser.CUIT : '',
    address: editUser ? editUser.address : '',
    phone: editUser ? editUser.phone : '',
    roles: editUser ? editUser.roles[0].name : '',
    email: editUser ? editUser.email : '',
    enabled: editUser ? Boolean(editUser.enabled) : true,
  });

  const { errors, validateForm, onBlurField } = useUsersValidator(formState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate(-1);
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };

    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });

    if (!isValid) {
      toast.error('Los datos ingresados no son válidos');
      return;
    }

    const newForm = {
      id: formState.id,
      fullName: formState.fullName,
      CUIT: Number(formState.CUIT),
      address: formState.address,
      phone: formState.phone,
      email: formState.email,
      enabled: Boolean(formState.enabled),
      commissions: formState.commissions,
      roles: formState.roles,
    };

    if (editUser) {
      dispatch(editsUser({
        id: formState.id, _body: newForm
      }));
      navigate('/user');
    } else {
      const createForm = {
        ...newForm,
        password: 123456789,
        password_confirmation: 123456789,
        user_color: generateColor(),
      };
      dispatch(createUser(createForm));
      navigate('/user');
    }
  };

  return {
    formState,
    errors,
    onInputChange,
    handleEnableChange,
    onBlurField,
    handleFormSubmit,
    handleClick,
  };
}

export default useUserFormLogic;
