import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavSec from '../NavSec/NavSec';
import { userOptions } from '../../../helpers/dataOptions';
import getUsers, { getRoles } from '../../../data/redux/users/thunks';
import { setLoading } from '../../../data/redux/users/userSlice';
import Spinner from '../Spinner/Spinner';
import UserDesktop from './UserDesktop';
import UserMobile from './UserMobile';

function UserComponent() {
  const dispatch = useDispatch();
  const { loading, roles } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getUsers());

    if (roles?.length === 0) {
      dispatch(getRoles());
    }
    dispatch(setLoading(true));
  }, []);

  const [width, setWidth] = useState(1024);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    if (window.innerWidth <= 860) {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return !loading ? (
    <div className="container-main-user">
      <NavSec title="USUARIO" content={ userOptions } />
      <div className="container-body-user">
        {width <= 860 ? <UserMobile /> : <UserDesktop />}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default UserComponent;
