import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useElementHider from '../../hooks/useElementHider';
import { getConfig } from '../../../data/redux/UserConfig/thunks';
import { startLogout } from '../../../data/redux/auth/thunks';
import Logo from '../../assets/images/logo-myb.svg';
import { startCleanMU } from '../../../data/redux/Units/thunks';

function NavBar() {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useElementHider(setDropdown);
  const { loading } = useSelector((state) => state.userconfig);
  const { displayName } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig());
  }, [loading]);

  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  function active() {
    setDropdown(!dropdown);
  }

  // // Definimos una función que genera un color al azar en formato hexadecimal
  // const generateColor = () => {
  //   // Generamos 3 componentes de color al azar (rojo, verde y azul)
  //   const red = Math.floor(Math.random() * 256)
  //     .toString(16)
  //     .padStart(2, '0')
  //   const green = Math.floor(Math.random() * 256)
  //     .toString(16)
  //     .padStart(2, '0')
  //   const blue = Math.floor(Math.random() * 256)
  //     .toString(16)
  //     .padStart(2, '0')

  //   // Combinamos los componentes en un solo string hexadecimal
  //   const colorHex = `#${red}${green}${blue}`

  //   return colorHex
  // }

  const { auth } = useSelector((state) => state);

  const logout = () => {
    dispatch(startCleanMU());
    dispatch(startLogout(auth));
  };

  useEffect(() => {
    // Usamos la función para generar un color al azar y asignarlo como background de un elemento HTML
    const elemento = document.getElementById('avatar-perfil');
    elemento.style.backgroundColor =
      auth?.user_color !== null ? auth?.user_color : '#e56da1';
  }, []);

  return (
    <div className="contenedor-general-padre">
      <div className="navbar-container">
        <div className="nav-bar-background">.</div>
        <div className="nav-bar-background-desktop">.</div>
        <div className="container-btn-navbar">
          <span className="material-icons-round setting-icons">
            <Link to="/config">settings</Link>
          </span>
          <div className="div-gral-logo">
            <button
              onClick={ () => handleClick('/home') }
              className="logo-myb"
              type="button"
            >
              <img className="logo-base" src={ Logo } alt="" />
            </button>
          </div>
          <div className="hello-Us-NavBar">
            <h1>Bienvenido</h1>
            <p>{displayName}</p>
          </div>
          <div className="container-navbar-user" ref={ dropdownRef }>
            <button className="btn-dropdown" type="button" onClick={ active }>
              <div id="avatar-perfil" className="avatar-perfil">
                <p>{displayName?.slice(0, 1)}</p>
              </div>
              {dropdown ? (
                <div className="container-dropdown">
                  <Link to="/user/config">
                    <span className="material-icons-round">
                      manage_accounts
                    </span>
                  </Link>
                  <button
                    className="close-sesion-btn"
                    type="button"
                    onClick={ logout }
                  >
                    <span className="material-icons-round">logout</span>
                  </button>
                </div>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
