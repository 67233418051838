import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

// eslint-disable-next-line import/no-cycle
import {
  editFilterFields,
  editReport,
  onLoadingOrder,
  onLoadingSearch,
  selectClients,
  selectCurrencies,
  selectItems,
  selectOrderStatus,
  selectPaymentStatus,
  selectUnits,
  selectUsers,
} from './reportSlice';

const getOrders = createAsyncThunk('order/getOrders', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/orders`,
      config()
    );
    return data.orders;
  } catch (error) {
    toast(error);
    return [];
  }
});

export default getOrders;

export const getPaymentStatus = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/payment_statuses`,
      config()
    );
    dispatch(selectPaymentStatus(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getOrderStatus = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/order_statuses`
    );
    dispatch(selectOrderStatus(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getCurrencies = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/currencies`,
      config()
    );
    dispatch(selectCurrencies(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getUnits = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/measurement-unit`,
      config()
    );
    dispatch(selectUnits(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getItems = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/item`,
      config()
    );
    dispatch(selectItems(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getClients = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/clients`,
      config()
    );
    dispatch(selectClients(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}account/v1/users`,
      config()
    );
    dispatch(selectUsers(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getReport = (form) => async (dispatch) => {
  dispatch(onLoadingSearch());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/reports`,
      form,
      config()
    );

    dispatch(editReport(data.orders));
    return;
  } catch (data) {
    toast.error(data.message);
  } finally {
    dispatch(editFilterFields(form));
  }
};

export const downloadReport = (form) => async () => {
  axios
    .post(
      `${process.env.REACT_APP_API_BACKEND}api/report-download`,
      form,
      config()
    )
    .then((resp) => {
      if (resp.status === 200) {
        const link = document.createElement('a');
        link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
          resp.data
        )}`;
        const currentDate = new Date();
        if (form.nameReport) {
          link.setAttribute('download', form.nameReport);
        } else {
          const fileName = `report-${currentDate.getFullYear()}-${currentDate.getMonth() + 1
          }-${currentDate.getDate()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}-${currentDate.getMilliseconds()}.csv`;
          link.setAttribute('download', fileName);
        }
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((resp) => {
      toast.error(resp.response.data.message);
    });
};
