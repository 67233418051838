import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function NavSec({ title, content }) {
  const { permissions } = useSelector((state) => state.auth);
  const [toggle, setToggle] = useState(true);

  function active() {
    setToggle(!toggle);
  }

  return (
    <nav className="container-navBar">
      <button
        className={ toggle ? '' : 'active-button' }
        type="button"
        onClick={ active }
      >
        <h1>{title}</h1>
        <span className="material-icons-outlined">expand_more</span>
      </button>
      <ul className={ toggle ? 'hide' : 'visible' }>
        {content.map((item) =>
          permissions.includes(item.permission) ? (
            <li key={ item.section }>
              <Link to={ item.path }>{item.section}</Link>
            </li>
          ) : (
            <div className="li-desactive" key={ item.section }>
              <p>{item.section}</p>
            </div>
          )
        )}
      </ul>
    </nav>
  );
}

NavSec.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape),
};

NavSec.defaultProps = {
  title: 'Secciones',
  content: null,
};

export default NavSec;
