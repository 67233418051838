import ButtonClientsModDesktop from '../components/ButtonClientsModDesktop';
import { CLIENT_FINAL_ID } from '../../../../helpers/rolesConstants';

const columnsTableDesktop = [
  {
    name: 'CLIENTE',
    selector: (row) =>
      row?.client_type?.id === CLIENT_FINAL_ID
        ? `${row?.id} - ${row?.fullname}`
        : `${row?.id} - ${row?.company} | ${row?.fullname}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'SALDO',
    selector: (row) =>
      `${row?.orders?.length
        ? `US$ ${row?.orders?.[0]?.total_dollar_balance} - $ ${row?.orders?.[0]?.total_peso_balance}`
        : 0
      }`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'TIPO',
    selector: (row) => row?.client_type?.client_type,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'BONIFICACIONES',
    selector: (row) => `% ${row?.bonification}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: '',
    cell: (row) => ButtonClientsModDesktop(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
