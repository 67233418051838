// const token = JSON.parse(window.localStorage.getItem('persist:auth'))?.token
// const config = {
//   headers: {},
// }

// if (token) {
//   // eslint-disable-next-line no-useless-escape
//   config.headers.Authorization = `Bearer ${token.replace(/\"/g, '')}`
// }

// export default config

// import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

// const config = fetchBaseQuery({
//   prepareHeaders: (headers) => {
//     const token = JSON.parse(window.localStorage.getItem('persist:auth'))?.token
//     console.log('👀 ~ token:', token)
//     if (token) {
//       // eslint-disable-next-line no-useless-escape
//       headers.set('authorization', `Bearer ${token.replace(/\"/g, '')}`)
//     }
//     console.log('👀 ~ headers:', headers)
//     return headers
//   },
// })

// export default config

const configMultipart = () => {
  const token = JSON.parse(window.localStorage.getItem('persist:auth'))?.token;
  const headers = {
    headers: {
      authorization: '',
      'Content-Type': 'multipart/form-data',
    },
  };
  if (token) {
    headers.headers.authorization = `Bearer ${token.replace(/"/g, '')}`;
  }
  return headers;
};

export default configMultipart;
