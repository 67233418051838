import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import Table from '../Table/Table';
import getItem, {
  downloadTemplate,
  getDownloadItems,
  loadMassiveItems,
  uploadMassiveItems,
} from '../../../data/redux/Items/thunks';
import { editItems, setLoading } from '../../../data/redux/Items/itemSlice';
import InputField from '../InputField/InputField';
import useForm from '../../hooks/useForm';
import usePercentValidator from '../../hooks/usePercentValidator';
import DetailsTableDesktop from './components/DetailsTableDesktop';
import columnsTableDesktop from './Tables/columnsTableDesktop';

function ItemsDesktop() {
  const [toggle, setToggle] = useState(false);
  const [cargaM, setCarga] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [increment, setIncrement] = useState(true);

  const { formState, setFormState } = useForm({
    percent: 0,
  });

  const { errors, validateForm, onBlurField } = usePercentValidator(formState);

  const accepted = [
    '.csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const extensions = ['csv', 'xlsx', 'xls'];

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const regex = /^\d+[.,]?\d*$/;

    if (regex.test(value) && value !== '0') {
      document
        .getElementById('done_button_percent')
        .classList.remove('disabled');
    } else {
      document.getElementById('done_button_percent').classList.add('disabled');
    }

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const dispatch = useDispatch();
  const { item, loading } = useSelector((state) => state.item);
  const { permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getItem());
    dispatch(setLoading(false));
  }, []);

  function modMasiva() {
    setToggle(!toggle);
  }

  function carMasiva() {
    setCarga(!cargaM);
  }

  function checkExtension(data) {
    return extensions.includes(data.substring(data.lastIndexOf('.') + 1));
  }

  function onChangeFile(e) {
    e.preventDefault();
    const file = e.target.files[0];

    if (file && checkExtension(file.name)) {
      return toast.success('Archivo cargado correctamente');
    }
    return toast.error('Error en el archivo');
  }

  function onSubmitFile(e) {
    e.preventDefault();
    const file = e.target[0].files[0];
    if (file && checkExtension(file.name)) {
      const form = new FormData();
      form.append('file', file);
      dispatch(loadMassiveItems(form, item));
    } else {
      return toast.error('No se cargó ningún archivo');
    }
    return null;
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedItems(selectedRows.map((i) => i.id));
  };

  const handleMassiveUpload = () => {
    if (selectedItems?.length !== 0) {
      const form = {
        items: selectedItems,
        percent: increment
          ? Number(formState?.percent)
          : Number(formState?.percent) * -1,
      };
      dispatch(uploadMassiveItems(form));
      const newItems = item?.map((i) => ({
        ...i,
        price: parseFloat((i.price * (1 + form.percent / 100)).toFixed(2)),
      }));
      dispatch(editItems(newItems));
      setToggle(!toggle);
    } else {
      return toast.error(
        'Seleccione al menos 1 item para usar la modificación masiva'
      );
    }
    return null;
  };

  const handleIncrement = () => {
    setIncrement(!increment);
  };

  return (
    !loading && (
      <div className={ toggle ? 'check-active' : 'container-body-articles' }>
        <Table
          className="marmoleria--datatable table-article"
          selectRows={ toggle }
          columnsTable={ columnsTableDesktop }
          filteredData={ item }
          detailsTable={ DetailsTableDesktop }
          filterEnabled
          conceptsFilter={ [
            'id',
            'item',
            'price',
            'nomenclature.nomenclature',
            'currency.currency',
          ] }
          section="item"
          onSelectedRowsChange={ handleChange }
          messageNoData={
            <h1 className="no-data-message">NO SE CARGARON ARTÍCULOS</h1>
          }
        />

        <div className="container-btn-article">
          {toggle && (
            <>
              <form
                action=""
                onSubmit={ onSubmitFile }
                className="container-form-masiva"
              >
                <label className="ctn-check" htmlFor="a">
                  {increment ? 'AUMENTO' : 'DESCUENTO'}
                  <input
                    id="adjustment"
                    name="adjustment"
                    className="inp-check"
                    type="checkbox"
                    onChange={ handleIncrement }
                    defaultValue={ increment }
                  />
                </label>
                <InputField
                  type="amount"
                  name="percent"
                  id="percent"
                  value={ formState?.percent }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors?.percent.error ? 'red-advertise' : '' }
                  min={ 0 }
                />
                <div className="container-button">
                  <button
                    id="done_button_percent"
                    type="button"
                    onClick={ handleMassiveUpload }
                    disabled={ errors?.percent.error || formState?.percent === 0 }
                    className={ `${!toggle ? 'car-hidden' : 'btn-Masiva'
                    } disabled` }
                  >
                    <span className="material-icons-outlined">done</span>
                  </button>
                  <button
                    onClick={ modMasiva }
                    className={ !toggle ? 'car-hidden' : 'btn-Masiva' }
                    type="button"
                  >
                    <span className="material-icons-outlined">close</span>
                  </button>
                </div>
              </form>
              <p className="error-validation error-percent">
                {errors.percent.message}
              </p>
            </>
          )}

          {permissions?.includes('item-create') && (
            <div className="container-button-masiva">
              <button
                onClick={ modMasiva }
                className={ !toggle && !cargaM ? 'btn-Masiva' : 'car-hidden' }
                type="button"
              >
                MODIFICACIÓN MASIVA
              </button>
              {cargaM ? (
                <form
                  action=""
                  onSubmit={ onSubmitFile }
                  className="container-form-masiva"
                >
                  <input
                    type="file"
                    accept={ accepted.join(',') }
                    className="cargaM"
                    id="fileSelector"
                    onChange={ onChangeFile }
                  />
                  <div className="container-button">
                    <button
                      className={ toggle ? 'car-hidden' : 'btn-Masiva' }
                      type="submit"
                    >
                      <span className="material-icons-outlined">done</span>
                    </button>
                    <button
                      onClick={ carMasiva }
                      className={ toggle ? 'car-hidden' : 'btn-Masiva' }
                      type="button"
                    >
                      <span className="material-icons-outlined">close</span>
                    </button>
                  </div>
                </form>
              ) : (
                <button
                  onClick={ carMasiva }
                  className={ toggle ? 'car-hidden' : 'btn-Masiva' }
                  type="button"
                >
                  CARGA MASIVA
                </button>
              )}
              {!cargaM ? (
                <button
                  onClick={ () => getDownloadItems() }
                  className={ toggle ? 'car-hidden' : 'btn-Masiva' }
                  type="button"
                >
                  DESCARGA MASIVA
                </button>
              ) : null}
              {!cargaM ? (
                <button
                  onClick={ () => downloadTemplate() }
                  className={ toggle ? 'car-hidden' : 'btn-Masiva' }
                  type="button"
                >
                  ARCHIVO
                </button>
              ) : null}
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default ItemsDesktop;
