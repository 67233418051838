import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import Table from '../Table/Table';
import { reportsOptions, rolesOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import Spinner from '../Spinner/Spinner';
import InnerSpinner from '../InnerSpinner/InnerSpinner';
import {
  downloadReport,
  getClients,
  getCurrencies,
  getItems,
  getOrderStatus,
  getPaymentStatus,
  getReport,
  getUnits,
  getUsers,
} from '../../../data/redux/Reports/thunks';
import {
  editFilterFields,
  editReport,
} from '../../../data/redux/Reports/reportSlice';
import useReportValidator from '../../hooks/useReportValidator';

const columnsTable = [
  {
    name: 'USUARIO',
    selector: (row) => `${row.user.fullName}`,
    sortable: true,
    minWidth: '33%',
  },
  {
    name: 'CLIENTE',
    selector: (row) => `${row.client?.fullname}`,
    sortable: true,
    minWidth: '33%',
  },
  {
    name: 'VENTAS',
    selector: (row) =>
      `USD ${row.dollar_balance} - 
    ARS ${row.peso_balance}`,
    sortable: true,
    minWidth: '33%',
  },
];

function calcularCantidadItems(sectors) {
  let items = 0;
  sectors.forEach((sect) => {
    sect.item_list.forEach((it) => {
      if (it.quantity) {
        items += it.quantity;
      } else {
        items += 1;
      }
    });
  });
  return items;
}

function DetailsTable({ data }) {
  return (
    <div className="container-detail-orders">
      <div className="container-detail-main-orders">
        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Fecha inicial:</h3>
            <p className="p-Details">{ data.start_date.slice(0, 10) }</p>
            <h3 className="p-title-detail">Fecha de vigencia:</h3>
            <p className="p-Details">{ data.effective_date.slice(0, 10) }</p>
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">N° de cliente:</h3>
            <p className="p-Details">{ data.client?.id }</p>
            <h3 className="p-title-detail">Tipo de cliente:</h3>
            <p className="p-Details">{ data.client?.client_type.client_type }</p>
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Bonificación:</h3>
            <p className="p-Details">
              { `${data.client?.bonification ? data.client?.bonification : 0}%` }
            </p>
          </div>
        </div>
        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Estado del pedido:</h3>
            <p className="p-Details">{ data.order_status.order_status }</p>
            <h3 className="p-title-detail">Artículos:</h3>
            <p className="p-Details">
              { `${calcularCantidadItems(data.sectors)} Artículos` }
            </p>
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Saldo en USD:</h3>
            <p className="p-Details saldos">{ data.dollar_balance }</p>
            <h3 className="p-title-detail">Saldo en ARS:</h3>
            <p className="p-Details saldos">{ data.peso_balance }</p>
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Estado de pago:</h3>
            <p className="p-Details">{ data.payment_status.payment_status }</p>
          </div>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

function ReportsGenerated() {
  const dispatch = useDispatch();
  const [finded, setFinded] = useState(false);

  const {
    loading,
    searchLoading,
    paymentStatuses,
    orderStatuses,
    currencies,
    measurementunit,
    items,
    clients,
    users,
    filterFields,
    reportOrders,
  } = useSelector((state) => state.report);

  const { formState, setFormState } = useForm({
    fromDate: '',
    period: '',
    toDate: '',
    comparison: false,
    quote: false,
    userId: '',
    rolId: '',
    clientId: '',
    clientTypeId: '',
    isOpen: '',
    commPaid: '',
    orderStatusId: '',
    paymentStatusId: '',
    itemId: '',
    currencyId: '',
    unitId: '',
    nameReport: '',
  });

  const { errors, validateForm, onBlurField } = useReportValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value, type, checked } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  useEffect(() => {
    if (paymentStatuses?.length === 0) dispatch(getPaymentStatus());
    if (orderStatuses?.length === 0) dispatch(getOrderStatus());
    if (currencies?.length === 0) dispatch(getCurrencies());
    dispatch(getUnits());
    dispatch(getItems());
    dispatch(getClients());
    dispatch(getUsers());
    dispatch(editReport([]));
    dispatch(editFilterFields(formState));
  }, []);

  const handleSelectToDate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      period: '',
    });
  };

  const handleSelectPeriod = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      toDate: '',
    });
  };

  const handleSearch = () => {
    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });

    if (!isValid) return toast.error('Los datos ingresados no son válidos');
    if (formState.toDate === '' && formState.period === '')
      return toast.error(
        'Debe seleccionar una fecha o un periodo para realizar una búsqueda'
      );

    setFinded(true);
    const newForm = {
      fromDate: formState.fromDate,
      period: Number(formState.period) ? Number(formState.period) : null,
      toDate: formState.toDate ? formState.toDate : null,
      comparison: formState.comparison,
      quote: formState.quote,
      userId: Number(formState.userId) ? Number(formState.userId) : null,
      rolId: Number(formState.rolId) ? Number(formState.rolId) : null,
      clientId: Number(formState.clientId) ? Number(formState.clientId) : null,
      clientTypeId: Number(formState.clientTypeId)
        ? Number(formState.clientTypeId)
        : null,
      isOpen:
        formState.isOpen === '' ? null : Boolean(Number(formState.isOpen)),
      commPaid:
        formState.commPaid === '' ? null : Boolean(Number(formState.commPaid)),
      orderStatusId: Number(formState.orderStatusId)
        ? Number(formState.orderStatusId)
        : null,
      paymentStatusId: Number(formState.paymentStatusId)
        ? Number(formState.paymentStatusId)
        : null,
      itemId: Number(formState.itemId) ? Number(formState.itemId) : null,
      currencyId: Number(formState.currencyId)
        ? Number(formState.currencyId)
        : null,
      unitId: Number(formState.unitId) ? Number(formState.unitId) : null,
      nameReport: formState.nameReport ? formState.nameReport : null,
    };
    dispatch(getReport(newForm));
    return null;
  };

  const handleDownload = () => {
    const regex =
      /^(?!^(PRN|AUX|CLOCK\$|NUL|CON|COM\d|LPT\d|\..*)(\..+)?$)[^\x00-\x1f\\?*:";|/]+$/gm;

    if (formState.nameReport !== '' && !regex.test(formState.nameReport)) {
      return toast.error('El nombre de archivo no es válido');
    }

    const newForm = {
      fromDate: formState.fromDate,
      period: Number(formState.period) ? Number(formState.period) : null,
      toDate: formState.toDate ? formState.toDate : null,
      comparison: formState.comparison,
      quote: formState.quote,
      userId: Number(formState.userId) ? Number(formState.userId) : null,
      rolId: Number(formState.rolId) ? Number(formState.rolId) : null,
      clientId: Number(formState.clientId) ? Number(formState.clientId) : null,
      clientTypeId: Number(formState.clientTypeId)
        ? Number(formState.clientTypeId)
        : null,
      isOpen:
        formState.isOpen === '' ? null : Boolean(Number(formState.isOpen)),
      commPaid:
        formState.commPaid === '' ? null : Boolean(Number(formState.commPaid)),
      orderStatusId: Number(formState.orderStatusId)
        ? Number(formState.orderStatusId)
        : null,
      paymentStatusId: Number(formState.paymentStatusId)
        ? Number(formState.paymentStatusId)
        : null,
      itemId: Number(formState.itemId) ? Number(formState.itemId) : null,
      currencyId: Number(formState.currencyId)
        ? Number(formState.currencyId)
        : null,
      unitId: Number(formState.unitId) ? Number(formState.unitId) : null,
      nameReport: formState.nameReport ? formState.nameReport : null,
    };
    dispatch(downloadReport(newForm));
    return '';
  };
  /* eslint-enable no-control-regex */

  const handleCleanFilters = () => {
    setFinded(false);
    setFormState({
      fromDate: '',
      period: '',
      toDate: '',
      comparison: false,
      quote: false,
      userId: '',
      rolId: '',
      clientId: '',
      clientTypeId: '',
      isOpen: '',
      commPaid: '',
      orderStatusId: '',
      paymentStatusId: '',
      itemId: '',
      currencyId: '',
      unitId: '',
      nameReport: '',
    });
    dispatch(editReport([]));
  };

  useEffect(() => {
    setFormState({
      fromDate: filterFields.fromDate ? filterFields.fromDate : '',
      period: filterFields.period ? filterFields.period : '',
      toDate: filterFields.toDate ? filterFields.toDate : '',
      comparison: filterFields.comparison ? filterFields.comparison : false,
      quote: filterFields.quote ? filterFields.quote : false,
      userId: filterFields.userId ? filterFields.userId : '',
      rolId: filterFields.rolId ? filterFields.rolId : '',
      clientId: filterFields.clientId ? filterFields.clientId : '',
      clientTypeId: filterFields.clientTypeId ? filterFields.clientTypeId : '',
      isOpen:
        typeof filterFields.isOpen === 'boolean'
          ? Number(filterFields.isOpen)
          : '',
      commPaid:
        typeof filterFields.commPaid === 'boolean'
          ? Number(filterFields.commPaid)
          : '',
      orderStatusId: filterFields.orderStatusId
        ? filterFields.orderStatusId
        : '',
      paymentStatusId: filterFields.paymentStatusId
        ? filterFields.paymentStatusId
        : '',
      itemId: filterFields.itemId ? filterFields.itemId : '',
      currencyId: filterFields.currencyId ? filterFields.currencyId : '',
      unitId: filterFields.unitId ? filterFields.unitId : '',
      nameReport: filterFields.nameReport ? filterFields.nameReport : '',
    });
  }, [filterFields]);

  return !loading ? (
    <div className="container-main-reports-gral">
      <NavSec title="REPORTES" content={ reportsOptions } />
      <div className="container-reports-main-generated">
        <div className="column-report">
          <div className="reports-input">
            <InputField
              label="* DESDE"
              name="fromDate"
              id="fromDate"
              value={ formState.fromDate }
              type="date"
              onChange={ onInputChange }
              onBlur={ onBlurField }
            />
          </div>
          <div className="container-client-form container-form">
            { errors.fromDate.dirty && errors.fromDate.error ? (
              <p className="error-validation">{ errors.fromDate.message }</p>
            ) : null }
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">PERÍODO</div>
              <select
                className="select-opt"
                name="period"
                id="period"
                onChange={ handleSelectPeriod }
                value={ formState.period }
                onBlur={ onBlurField }
              >
                <option className="option-select" default value="">
                  Seleccione un Periodo
                </option>
                <option className="option-select" value="1">
                  Diario
                </option>
                <option className="option-select" value="2">
                  Semanal
                </option>
                <option className="option-select" value="3">
                  Mensual
                </option>
                <option className="option-select" value="4">
                  Anual
                </option>
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">USUARIO</div>
              <select
                className="select-opt"
                name="userId"
                id="userId"
                onChange={ onInputChange }
                value={ formState.userId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { users?.map((user) => (
                  <option key={ user.id } value={ user.id }>
                    { `[${user.id}] ${user.fullName} / ${user.roles[0]?.name}` }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">ROL</div>
              <select
                className="select-opt"
                name="rolId"
                id="rolId"
                onChange={ onInputChange }
                value={ formState.rolId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { rolesOptions.map((item) => (
                  <option
                    className="option-select"
                    key={ item.id }
                    value={ item.id }
                  >
                    { item.displayName }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">ARTÍCULOS</div>
              <select
                className="select-opt"
                name="itemId"
                id="itemId"
                onChange={ onInputChange }
                value={ formState.itemId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { items?.map((it) => (
                  <option key={ it.id } value={ it.id }>
                    { `${it.nomenclature.nomenclature}-${it.id} | ${it.item}` }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">UNIDAD MEDIDA</div>
              <select
                className="select-opt"
                name="unitId"
                id="unitId"
                onChange={ onInputChange }
                value={ formState.unitId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { measurementunit?.map((unit) => (
                  <option key={ unit.id } value={ unit.id }>
                    { `${unit.nomenclature} (${unit.measurement_unit})` }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">ESTADO PEDIDO</div>
              <select
                className="select-opt"
                name="orderStatusId"
                id="orderStatusId"
                onChange={ onInputChange }
                value={ formState.orderStatusId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { orderStatuses?.map((orderStatus) => (
                  <option key={ orderStatus.id } value={ orderStatus.id }>
                    { orderStatus.order_status }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">COMISION PAGADA</div>
              <select
                className="select-opt"
                name="commPaid"
                id="commPaid"
                onChange={ onInputChange }
                value={ formState.commPaid }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                <option className="option-select" value={ 0 }>
                  No
                </option>
                <option className="option-select" value={ 1 }>
                  Si
                </option>
              </select>
            </label>
          </div>
        </div>

        <div className="column-report">
          <div className="reports-input">
            <InputField
              label="HASTA"
              name="toDate"
              id="toDate"
              value={ formState.toDate }
              type="date"
              onChange={ handleSelectToDate }
              onBlur={ onBlurField }
            />
          </div>

          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">CLIENTE</div>
              <select
                className="select-opt"
                name="clientId"
                id="clientId"
                onChange={ onInputChange }
                value={ formState.clientId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { clients?.map((client) => (
                  <option key={ client.id } value={ client.id }>
                    { `[${client.id}] ${client.fullname} / ${client.client_type.client_type}` }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">TIPO DE CLIENTE</div>
              <select
                className="select-opt"
                name="clientTypeId"
                id="clientTypeId"
                onChange={ onInputChange }
                value={ formState.clientTypeId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                <option className="option-select" value="1">
                  Final
                </option>
                <option className="option-select" value="2">
                  Conector
                </option>
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">MONEDA</div>
              <select
                className="select-opt"
                name="currencyId"
                id="currencyId"
                onChange={ onInputChange }
                value={ formState.currencyId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { currencies?.map((currency) => (
                  <option key={ currency.id } value={ currency.id }>
                    { currency.currency }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">ABIERTO/CERRADO</div>
              <select
                className="select-opt"
                name="isOpen"
                id="isOpen"
                onChange={ onInputChange }
                value={ formState.isOpen }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                <option className="option-select" value={ 0 }>
                  Cerrado
                </option>
                <option className="option-select" value={ 1 }>
                  Abierto
                </option>
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="report-label-select" htmlFor="as">
              { ' ' }
              <div className="text-label">ESTADO PAGO</div>
              <select
                className="select-opt"
                name="paymentStatusId"
                id="paymentStatusId"
                onChange={ onInputChange }
                value={ formState.paymentStatusId }
              >
                <option className="option-select" default value="">
                  Seleccione
                </option>
                { paymentStatuses?.map((paymentStatus) => (
                  <option key={ paymentStatus.id } value={ paymentStatus.id }>
                    { paymentStatus.payment_status }
                  </option>
                )) }
              </select>
            </label>
          </div>
          <div className="reports-input-select">
            <label className="ctn-check" htmlFor="as">
              { ' ' }
              <div className="text-label">COTIZACIÓN</div>
              <input
                type="checkbox"
                id="quote"
                name="quote"
                className="inp-check"
                onChange={ onInputChange }
                checked={ formState.quote }
              />
            </label>
          </div>
          <div className="reports-input-select">
            <label className="ctn-check" htmlFor="as">
              { ' ' }
              <div className="text-label">AÑO ANT.</div>
              <input
                type="checkbox"
                id="comparison"
                name="comparison"
                className="inp-check"
                onChange={ onInputChange }
                checked={ formState.comparison }
              />
            </label>
          </div>
          { reportOrders?.length !== 0 ? (
            <div className="reports-input-select">
              <label className="report-label-select" htmlFor="as">
                { ' ' }
                <div className="text-label">NOMBRE DEL REPORTE</div>
                <input
                  className="select-opt"
                  name="nameReport"
                  id="nameReport"
                  onChange={ onInputChange }
                  value={ formState.nameReport }
                />
              </label>
            </div>
          ) : (
            ''
          ) }
        </div>
      </div>
      <div className="container-btn-reports">
        <button className="btn-Main" type="button" onClick={ handleCleanFilters }>
          <span className="material-icons-outlined">close</span>
        </button>
        <button className="btn-Main" type="button" onClick={ handleSearch }>
          <span className="material-icons-outlined">manage_search</span>
        </button>
        { reportOrders?.length !== 0 ? (
          <button className="btn-Main" type="button" onClick={ handleDownload }>
            <span className="material-icons-outlined">save_alt</span>
          </button>
        ) : null }
      </div>
      { searchLoading ? (
        <InnerSpinner />
      ) : (
        <div className="container-table-reports">
          { reportOrders?.length > 0 ? (
            <Table
              className="marmoleria--datatable datatable-reports"
              selectRows={ false }
              columnsTable={ columnsTable }
              filteredData={ reportOrders }
              detailsTable={ DetailsTable }
              section="report"
            />
          ) : (
            <div className="no-results">
              { finded && <p>No se encontraron resultados</p> }
            </div>
          ) }
        </div>
      ) }
    </div>
  ) : (
    <Spinner />
  );
}

DetailsTable.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    effective_date: PropTypes.string,
    special_comm: PropTypes.bool,
    is_open: PropTypes.bool,
    version: PropTypes.number,
    peso_balance: PropTypes.number,
    dollar_balance: PropTypes.number,
    peso_bonification: PropTypes.number,
    dollar_bonification: PropTypes.number,
    peso_comm: PropTypes.number,
    dollar_comm: PropTypes.number,
    client_name: PropTypes.string,

    user: PropTypes.shape({
      id: PropTypes.number,
      fullname: PropTypes.string,
      CUIT: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      enabled: PropTypes.number,
    }),

    client: PropTypes.shape({
      id: PropTypes.number,
      fullname: PropTypes.string,
      cuit: PropTypes.number,
      email: PropTypes.string,
      address: PropTypes.string,
      bonification: PropTypes.number,
      observations: PropTypes.string,
      enabled: PropTypes.bool,
      client_type: PropTypes.shape({
        id: PropTypes.number,
        client_type: PropTypes.string,
      }),
    }),

    order_status: PropTypes.shape({
      id: PropTypes.number,
      order_status: PropTypes.string,
    }),

    payment_status: PropTypes.shape({
      id: PropTypes.number,
      payment_status: PropTypes.string,
    }),

    sectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        observations: PropTypes.string,
        order_id: PropTypes.number,
        sector_id: PropTypes.number,
        item_list: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            quantity: PropTypes.number,
            width: PropTypes.number,
            heigth: PropTypes.number,
            depth: PropTypes.number,
            observations: PropTypes.string,
            price: PropTypes.number,
            currency: PropTypes.string,
            bonification: PropTypes.number,
            order_sector_id: PropTypes.number,
            item_id: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
};

export default ReportsGenerated;
