import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';

function OrdersFinalCardItem({ item }) {
  const { loading } = useSelector((state) => state.order);
  const { roles } = useSelector((state) => state.auth);

  function calcularCantidad() {
    if (item.quantity) {
      return item.quantity;
    }
    if (item.depth) {
      return item.width * item.height * item.depth;
    }
    if (item.height) {
      return item.width * item.height;
    }
    if (item.width) {
      return item.width;
    }
    return 0;
  }

  function showPrice(i, price) {
    if (roles[0]?.name.includes('D-Operations')) {
      return '-';
    }
    const currency = i.currency === 'ARS' ? '$' : 'US$';
    return currency.concat(' ', price);
  }

  return !loading ? (
    <div className="container-card-orders">
      <div className="container-art-orders">
        <h3>ARTÍCULO</h3>
        <p>{item?.item?.item}</p>
        <h3>CÓDIGO</h3>
        <p>{`${item?.item?.nomenclature?.nomenclature}-${item?.item?.id}`}</p>
        <h3>UNIDAD</h3>
        <p>{`${calcularCantidad()} ${item?.item?.measurement_unit?.nomenclature
        }`}</p>
      </div>
      <div className="container-mini-card-uno">
        <h3>PRECIO U.</h3>
        <p>{showPrice(item, item.price)}</p>
        <h3>SUBTOTAL</h3>
        <p>{showPrice(item, item.subtotal_sector_item)}</p>
      </div>
      <div className="container-mini-card-dos">
        <p>{item.observations ? item.observations : 'Sin observaciones'}</p>
      </div>
      <div className="line-red-art">....................</div>
    </div>
  ) : (
    <Spinner />
  );
}

OrdersFinalCardItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number,
    depth: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    currency: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    bonification: PropTypes.number,
    observations: PropTypes.string,
    item_id: PropTypes.number.isRequired,
    order_sector_id: PropTypes.number.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number.isRequired,
      item: PropTypes.string.isRequired,
      depth: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      cost: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      description: PropTypes.string,
      enabled: PropTypes.bool,
      currency: PropTypes.shape({
        currency: PropTypes.string,
      }),
      measurement_unit: PropTypes.shape({
        measurement_unit: PropTypes.string,
        nomenclature: PropTypes.string,
      }),
      nomenclature: PropTypes.shape({
        nomenclature: PropTypes.string,
      }),
    }),
    subtotal_sector_item: PropTypes.number,
  }).isRequired,
};

export default OrdersFinalCardItem;
