import ButtonUnitsMod from '../components/ButtonUnitsMod';

const columnsTableDesktop = [
  {
    name: 'UNIDAD',
    selector: (row) => row?.measurement_unit,
    sortable: true,
    minWidth: '30%',
  },
  {
    name: 'NOMENCLATURA',
    selector: (row) => row?.nomenclature,
    sortable: true,
    minWidth: '30%',
  },
  {
    name: 'ENTERO / DECIMAL',
    selector: (row) => (row?.is_decimal ? 'DECIMAL' : 'ENTERO'),
    sortable: true,
    minWidth: '30%',
  },
  {
    name: '',
    cell: (row) => ButtonUnitsMod(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
