import img1 from './1H.png';
import img2 from './2H.png';
import img3 from './3H.png';
import img4 from './4H.png';
import img5 from './5H.png';
import img6 from './6H.png';

const userOptions = [
  {
    section: 'PEDIDOS', path: '/orders', permission: 'order-read'
  },
  {
    section: 'CLIENTES', path: '/client', permission: 'client-read'
  },
  {
    section: 'ARTÍCULOS', path: '/article', permission: 'item-read'
  },
  {
    section: 'UNIDADES', path: '/units', permission: 'unit-read'
  },
  {
    section: 'REPORTES', path: '/reports', permission: 'report-read'
  },
];
const clientOptions = [
  {
    section: 'PEDIDOS', path: '/orders', permission: 'order-read'
  },
  {
    section: 'USUARIOS', path: '/user', permission: 'user-read'
  },
  {
    section: 'ARTÍCULOS', path: '/article', permission: 'item-read'
  },
  {
    section: 'UNIDADES', path: '/units', permission: 'unit-read'
  },
  {
    section: 'REPORTES', path: '/reports', permission: 'report-read'
  },
];
const ordersOptions = [
  {
    section: 'CLIENTES', path: '/client', permission: 'client-read'
  },
  {
    section: 'ARTÍCULOS', path: '/article', permission: 'item-read'
  },
  {
    section: 'USUARIOS', path: '/user', permission: 'user-read'
  },
  {
    section: 'UNIDADES', path: '/units', permission: 'unit-read'
  },
  {
    section: 'REPORTES', path: '/reports', permission: 'report-read'
  },
];
const articleOptions = [
  {
    section: 'PEDIDOS', path: '/orders', permission: 'order-read'
  },
  {
    section: 'CLIENTES', path: '/client', permission: 'client-read'
  },
  {
    section: 'USUARIOS', path: '/user', permission: 'user-read'
  },
  {
    section: 'UNIDADES', path: '/units', permission: 'unit-read'
  },
  {
    section: 'REPORTES', path: '/reports', permission: 'report-read'
  },
];
const unitsOptions = [
  {
    section: 'USUARIOS', path: '/user', permission: 'user-read'
  },
  {
    section: 'PEDIDOS', path: '/orders', permission: 'order-read'
  },
  {
    section: 'CLIENTES', path: '/client', permission: 'client-read'
  },
  {
    section: 'ARTÍCULOS', path: '/article', permission: 'item-read'
  },
  {
    section: 'REPORTES', path: '/reports', permission: 'report-read'
  },
];
const reportsOptions = [
  {
    section: 'PEDIDOS', path: '/orders', permission: 'order-read'
  },
  {
    section: 'CLIENTES', path: '/client', permission: 'client-read'
  },
  {
    section: 'USUARIOS', path: '/user', permission: 'user-read'
  },
  {
    section: 'ARTÍCULOS', path: '/article', permission: 'item-read'
  },
  {
    section: 'UNIDADES', path: '/units', permission: 'unit-read'
  },
];
const sectionsOptions = [
  {
    section: 'PEDIDOS',
    path: '/orders',
    permission: 'order-read',
    description:
      'Permite cargar, editar y registrar pagos de las órdenes de compra en la aplicación. Es útil para llevar un registro organizado y actualizado de los pedidos.',
    image: img1,
  },
  {
    section: 'CLIENTES',
    path: '/client',
    permission: 'client-read',
    description:
      'Permite registrar y editar información de los clientes en la aplicación',
    image: img2,
  },
  {
    section: 'ARTICULOS',
    path: '/article',
    permission: 'item-read',
    description: `Permite cargar y editar información de productos o artículos en la aplicación, de manera manual o masiva.
    Es útil para mantener actualizado el inventario y gestionarlo eficientemente.`,
    image: img3,
  },
  {
    section: 'USUARIOS',
    path: '/user',
    permission: 'user-read',
    description:
      'Permite crear y editar usuarios en la aplicación, asignándoles un rol específico. Es útil para gestionar los accesos y permisos de los usuarios en la aplicación.',
    image: img4,
  },
  {
    section: 'UNIDADES',
    path: '/units',
    permission: 'unit-read',
    description:
      'Permite crear y editar las unidades de medida de los artículos que serán vendidos en la aplicación.',
    image: img5,
  },
  {
    section: 'REPORTES',
    path: '/reports',
    permission: 'report-read',
    description:
      'La sección de reportes permite parametrizar y filtrar información para obtener el reporte deseado en la aplicación',
    image: img6,
  },
];

const rolesOptions = [
  {
    id: '1', name: 'Super-Admin', displayName: 'Administrador'
  },
  {
    id: '2', name: 'D-Sales', displayName: 'Director de Ventas'
  },
  {
    id: '3', name: 'Seller-Senior', displayName: 'Vendedor Senior'
  },
  {
    id: '4', name: 'Seller-Junior', displayName: 'Vendedor Junior'
  },
  {
    id: '5', name: 'D-Operations', displayName: 'Director de Operaciones'
  },
];

export {
  userOptions,
  clientOptions,
  ordersOptions,
  articleOptions,
  unitsOptions,
  reportsOptions,
  sectionsOptions,
  rolesOptions,
};
