import { useState } from 'react';
import {
  numberValidator,
  percentValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const usePercentValidator = (formState) => {
  const [errors, setErrors] = useState({
    percent: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { percent } = form;

    if (nextErrors.percent.dirty && (field ? field === 'percent' : true)) {
      const percentMessage = numberValidator(percent, form);
      nextErrors.percent.error = !!percentMessage;
      nextErrors.percent.message = percentMessage;
      if (percentMessage) isValid = false;

      const percentClientMessage = percentValidator(percent);
      nextErrors.percent.error = !!percentClientMessage;
      nextErrors.percent.message = percentClientMessage;
      if (percentClientMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };
    validateForm({
      form: formState, field, errors: updatedErrors
    });
  };

  return {
    ...formState,
    formState,
    errors,
    validateForm,
    onBlurField,
  };
};

export default usePercentValidator;
