import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

// eslint-disable-next-line import/no-cycle
import {
  addMeasurementUnit,
  cleanMeasurementUnit,
  deleteMeasurementUnit,
  editMeasurementUnit,
  onLoadingMeasurementUnit,
  selectUnitTypes,
} from './measurementUnitSlice';

const getMeasurementUnit = createAsyncThunk(
  'measurementunit/getMeasuarementUnits',
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BACKEND}api/measurement-unit`,
        config()
      );
      return data.original;
    } catch (error) {
      return error;
    }
  }
);

export default getMeasurementUnit;

export const createMeasurement = (form) => async (dispatch) => {
  dispatch(onLoadingMeasurementUnit());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/measurement-unit`,
      form,
      config()
    );
    dispatch(addMeasurementUnit(data.page));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const editsMeasurement =
  ({ id, _body }) =>
    async (dispatch) => {
      dispatch(onLoadingMeasurementUnit());
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/measurement-unit/${id}`,
          _body,
          config()
        );
        dispatch(editMeasurementUnit(data.page));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const deletesMeasurement = (id) => async (dispatch) => {
  // dispatch(onLoadingMeasurementUnit())
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}api/measurement-unit/${id}`,
      config()
    );
    dispatch(deleteMeasurementUnit(id));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getUnitTypes = () => async (dispatch) => {
  dispatch(onLoadingMeasurementUnit());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/unit_types`,
      config()
    );
    dispatch(selectUnitTypes(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const startCleanMU = () => (dispatch) => {
  dispatch(cleanMeasurementUnit());
};
