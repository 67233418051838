import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../../Table/Table';
import QuotesColumnsDesktop from './QuotesColumnsDesktop';
import QuotesDetailsTableDesktop from './QuotesDetailsTableDesktop';

function QuotesDesktop() {
  const { quote } = useSelector((state) => state.quote);

  const [newQuotes, setNewQuotes] = useState(quote);
  const { permissions } = useSelector((state) => state.auth);
  const { columnsTableDesktop, quotes } = QuotesColumnsDesktop();

  let newQuote;
  let updatedQuotes;

  useEffect(() => {
    setNewQuotes(
      newQuotes.map((prevOrder) => {
        if (prevOrder.id === quotes.id) {
          return {
            ...prevOrder,
            is_open: quotes.is_open,
          };
        }
        return prevOrder;
      })
    );
  }, [quotes]);

  useEffect(() => {
    setNewQuotes(quote);
    updatedQuotes = newQuotes?.map((ord) => {
      if (ord.client) {
        newQuote = {
          ...ord, client_name: ord.client.fullname
        };
        return newQuote;
      }
      return ord;
    });
  }, [quote]);

  updatedQuotes = quote.map((ord) => {
    if (ord.client) {
      newQuote = {
        ...ord, client_name: ord?.client?.fullname
      };
      return newQuote;
    }
    return ord;
  });

  return (
    <div className="container-body-articles">
      <Table
        className={
          permissions.includes('order-read')
            ? 'marmoleria--datatable table-orders'
            : 'marmoleria--datatable table-orders-sin'
        }
        selectRows={ false }
        columnsTable={
          permissions.includes('order-read')
            ? columnsTableDesktop
            : columnsTableDesktop.slice(0, -1)
        }
        filteredData={ updatedQuotes }
        filterOrders=""
        detailsTable={ QuotesDetailsTableDesktop }
        filterEnabled
        conceptsFilter={ [
          'client_name',
          'id',
          'peso_total',
          'dollar_total',
          'user.fullName',
          'user.CUIT',
          'user.email',
          'client.fullname',
          'client.cuit',
          'client.email',
          'client.company',
          'order_status.order_status',
          'payment_status.payment_status',
        ] }
        section="order"
        messageNoData={
          <h1 className="no-data-message">NO SE REALIZARON COTIZACIONES</h1>
        }
      />
      <div className="container-states-block" />
    </div>
  );
}

export default QuotesDesktop;
