import { useState } from 'react';
import {
  cuitValidator,
  emailValidator,
  fullNameValidator,
  phoneValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useConfigValidator = (formState) => {
  const [errors, setErrors] = useState({
    company_name: {
      dirty: false,
      error: false,
      message: '',
    },
    cuit: {
      dirty: false,
      error: false,
      message: '',
    },
    iva_position: {
      dirty: false,
      error: false,
      message: '',
    },
    address: {
      dirty: false,
      error: false,
      message: '',
    },
    phone: {
      dirty: false,
      error: false,
      message: '',
    },
    email: {
      dirty: false,
      error: false,
      message: '',
    },
    footer: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    // eslint-disable-next-line camelcase
    const { company_name, cuit, email, address, phone, footer } = form;

    if (
      nextErrors.company_name.dirty &&
      (field ? field === 'company_name' : true)
    ) {
      const companyNameMessage = fullNameValidator(company_name, form);
      nextErrors.company_name.error = !!companyNameMessage;
      nextErrors.company_name.message = companyNameMessage;
      if (companyNameMessage) isValid = false;
    }

    if (nextErrors.cuit.dirty && (field ? field === 'cuit' : true)) {
      const cuitMessage = cuitValidator(cuit, form);
      nextErrors.cuit.error = !!cuitMessage;
      nextErrors.cuit.message = cuitMessage;
      if (cuitMessage) isValid = false;
    }

    // if (
    //   nextErrors.iva_position.dirty &&
    //   (field ? field === 'iva_position' : true)
    // ) {
    //   const ivaPositionMessage = textValidator(iva_position, form)
    //   nextErrors.iva_position.error = !!ivaPositionMessage
    //   nextErrors.iva_position.message = ivaPositionMessage
    //   if (ivaPositionMessage) isValid = false
    // }

    if (nextErrors.address.dirty && (field ? field === 'address' : true)) {
      const addressMessage = textValidator(address, form);
      nextErrors.address.error = !!addressMessage;
      nextErrors.address.message = addressMessage;
      if (addressMessage) isValid = false;
    }

    if (nextErrors.phone.dirty && (field ? field === 'phone' : true)) {
      const phoneMessage = phoneValidator(phone, form);
      nextErrors.phone.error = !!phoneMessage;
      nextErrors.phone.message = phoneMessage;
      if (phoneMessage) isValid = false;
    }

    if (nextErrors.email.dirty && (field ? field === 'email' : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (emailMessage) isValid = false;
    }

    if (nextErrors.footer.dirty && (field ? field === 'footer' : true)) {
      const footerMessage = textValidator(footer, form);
      nextErrors.footer.error = !!footerMessage;
      nextErrors.footer.message = footerMessage;
      if (footerMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };
    validateForm({
      form: formState, field, errors: updatedErrors
    });
  };

  return {
    ...formState,
    formState,
    errors,
    validateForm,
    onBlurField,
  };
};

export default useConfigValidator;
