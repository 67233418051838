import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeOrder } from '../../../../data/redux/Orders/thunks';
import { editClient } from '../../../../data/redux/Clients/clientSlice';

function useOrders() {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();

  const handleConfirmCloseOrder = async (order) => {
    try {
      const updatedOrders = await closeOrder(order.id);
      const orderIsEdit = order;
      if (orderIsEdit) {
        updatedOrders.client = orderIsEdit.client;
        updatedOrders.user = orderIsEdit.user;
        updatedOrders.order_status = orderIsEdit.order_status;
        updatedOrders.sectors = orderIsEdit.sectors;
      }

      dispatch(editClient(updatedOrders));
      setOrders(updatedOrders);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    orders, handleConfirmCloseOrder
  };
}

export default useOrders;
