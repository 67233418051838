import React, {
  useEffect, useState, lazy, Suspense
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ordersOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import getOrders, { getClients } from '../../../data/redux/Orders/thunks';
import Spinner from '../Spinner/Spinner';
import getItem from '../../../data/redux/Items/thunks';
import getOrdersStatuses from '../../../data/redux/OrderStatuses/thunks';
import QuotesDesktop from './components/QuotesDesktop';
import QuotesMobile from './components/QuotesMobile';
import { getQuotes } from '../../../data/redux/Quotes/getQuotes';

const OrdersDesktop = lazy(() => import('./components/OrdersDesktop'));
const OrdersMobile = lazy(() => import('./components/OrdersMobile'));

function Orders() {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { quote } = useSelector((state) => state.quote);
  const { ordersStatuses } = useSelector((state) => state.orderStatuses);
  const [, setLoadingStatuses] = useState(true);
  const [, setNewOrders] = useState(order);
  const [, setNewQuotes] = useState(quote);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 860);
  const [isQuoteMode, setIsQuoteMode] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        dispatch(getOrders()),
        dispatch(getQuotes()),
        dispatch(getClients()),
        dispatch(getItem()),
        ordersStatuses?.length === 0 && dispatch(getOrdersStatuses()),
      ]);
      setLoadingStatuses(false);
    };
    loadData();
    setNewOrders(order);
    setNewQuotes(quote);
  }, []);

  useEffect(() => {
    const desktopMediaQuery = window.matchMedia('(min-width: 860px)');

    const updateIsMobile = () => {
      setIsMobile(!desktopMediaQuery.matches);
    };

    updateIsMobile();

    desktopMediaQuery.addEventListener('change', updateIsMobile);

    return () => {
      desktopMediaQuery.removeEventListener('change', updateIsMobile);
    };
  }, []);

  const selectComponent = () => {
    if (!isQuoteMode) {
      return !isMobile ? <OrdersDesktop /> : <OrdersMobile />;
    }
    return !isMobile ? <QuotesDesktop /> : <QuotesMobile />;
  };

  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setIsQuoteMode(query.get('view') === 'quotes' || location.pathname.includes('quotes'));
  }, [location.search, location.pathname]);

  return (
    <div className="container-main-orders-gral">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      <Suspense fallback={ <Spinner /> }>
        <div className='quotes'>
          <button type='button'
            className={ `qts-btn ${!isQuoteMode ? 'qts-act' : ''}` }
            onClick={ () => {
              setIsQuoteMode(false);
              window.history.pushState({
              }, '', '/orders?view=orders');
            } }
          >PEDIDOS</button>
          <button type='button'
            className={ `qts-btn ${isQuoteMode ? 'qts-act' : ''}` }
            onClick={ () => {
              setIsQuoteMode(true);
              window.history.pushState({
              }, '', '/orders?view=quotes');
            } }>COTIZACIONES</button>
        </div>
        {selectComponent()}
      </Suspense>
    </div>
  );
}

export default Orders;
