const clientSelected = (clientId, clients) => {
  const aux = clients?.filter((c) => c.id === Number(clientId));
  if (aux?.length !== 0 && aux) {
    return aux[0];
  }
  return {
    client_type: {
      id: 0
    }
  };
};

export default clientSelected;
