import { useState } from 'react';
import {
  clientCommValidator,
  numberValidator,
  specialCommValidator,
  userCommValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useCommValidator = (formState, commType) => {
  const [errors, setErrors] = useState({
    commPercent: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { commPercent } = form;

    if (
      nextErrors.commPercent.dirty &&
      (field ? field === 'commPercent' : true)
    ) {
      const commPercentMessage = numberValidator(commPercent, form);
      nextErrors.commPercent.error = !!commPercentMessage;
      nextErrors.commPercent.message = commPercentMessage;
      if (commPercentMessage) isValid = false;

      if (commType === 1) {
        // Validacion comision cliente
        const commPercentClientMessage = clientCommValidator(commPercent);
        nextErrors.commPercent.error = !!commPercentClientMessage;
        nextErrors.commPercent.message = commPercentClientMessage;
        if (commPercentClientMessage) isValid = false;
      } else if (commType === 2) {
        // Validacion comision especial cliente
        const commPercentClientMessage = specialCommValidator(commPercent);
        nextErrors.commPercent.error = !!commPercentClientMessage;
        nextErrors.commPercent.message = commPercentClientMessage;
        if (commPercentClientMessage) isValid = false;
      } else if (commType === 3) {
        // Validacion comision usuario (empleado)
        const commPercentUserMessage = userCommValidator(commPercent);
        nextErrors.commPercent.error = !!commPercentUserMessage;
        nextErrors.commPercent.message = commPercentUserMessage;
        if (commPercentUserMessage) isValid = false;
      }
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };
    validateForm({
      form: formState, field, errors: updatedErrors
    });
  };

  return {
    ...formState,
    formState,
    errors,
    validateForm,
    onBlurField,
  };
};

export default useCommValidator;
