import React from 'react';

const columnsTablePayment = [
  {
    name: 'N° P',
    selector: (row) => row?.id,
    sortable: true,
    width: '17%',
  },
  {
    name: 'CLIENTE',
    selector: (row) => row?.client?.fullname,
    sortable: true,
  },
  {
    name: 'SALDO',
    selector: (row) => (
      <div>
        <span className="text-green ml-2">
          US${row?.dollar_balance?.toFixed(2)} - $
          {row?.peso_balance?.toFixed(2)}
        </span>
      </div>
    ),
    sortable: true,
  },
];

export default columnsTablePayment;
