import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectOrder } from '../../../../data/redux/Orders/orderSlice';

function ButtonArrowClientOrders(row) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(selectOrder(row));
    navigate('/orders/final');
  };

  return (
    <div className="btn-client justify-content-center">
      <button type="button" onClick={ handleClick }>
        <span className="material-icons-outlined">chevron_right</span>
      </button>
    </div>
  );
}

export default ButtonArrowClientOrders;
