import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomPagination from './CustomPagination';

function Table({
  columnsTable,
  messageNoData,
  selectRows,
  filterEnabled,
  className,
  conditionalStyles,
  filteredData,
  filterOrders,
  conceptsFilter,
  section,
  expandedRow,
  detailsTable,
  onSelectedRowsChange,
  pagination,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const { permissions } = useSelector((state) => state.auth);
  const [currentRow, setCurrentRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [filterOrders]);

  const runFilters = (val) => {
    let updatedData = [];
    let result = [];
    if (val.length) {
      const filterByConcepts = (concepts, data, res) => {
        if (concepts.length === 0) {
          return res;
        }

        const concept = concepts[0];

        const filterData = data?.filter((item) => {
          let itemConcept;
          if (concept.includes('.')) {
            const [outerProperty, innerProperty, lastProperty] =
              concept.split('.');
            if (!lastProperty) {
              itemConcept = item[outerProperty][innerProperty];
            } else if (!innerProperty) {
              itemConcept = item[outerProperty];
            } else {
              itemConcept = item[outerProperty][innerProperty][lastProperty];
            }
          } else {
            itemConcept = item[concept];
          }
          const filter =
            itemConcept &&
            itemConcept
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase().trim());

          return filter;
        });

        const filteredResult = res.concat(filterData);

        return filterByConcepts(concepts.slice(1), filteredData, filteredResult);
      };

      result = filterByConcepts(conceptsFilter, filteredData, result);

      updatedData = result.reduce((acc, data) => {
        if (!acc.includes(data)) {
          acc.push(data);
        }
        return acc;
      }, []);
      updatedData.sort((a, b) => {
        const valueA = a[conceptsFilter[0]];
        const valueB = b[conceptsFilter[0]];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB);
        }
        return valueA - valueB;
      });

      setFilteredItems(updatedData);
      setSearchValue(val);
    }
  };
  const handleFilter = (e) => {
    const { value } = e.target;
    setSearchValue(value.trim());
    runFilters(value);

  };

  useEffect(() => {
    runFilters(searchValue);
  }, [filteredData, filterOrders]);

  const handleClean = (e) => {
    e.preventDefault();
    setSearchValue('');
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected);
  };

  const pageCount = searchValue?.length
    ? Math.ceil(filteredItems.length / 10)
    : Math.ceil(filteredData.length / 10) || 1;

  const passthroughPagination = () => (
    <CustomPagination
      currentPage={ currentPage }
      handlePagination={ handlePagination }
      pageCount={ pageCount }
    />
  );

  const { id } = useParams();

  return (
    <div className="datatable--responsive container">
      {filterEnabled ? (
        <div className="container-filter">
          {permissions?.includes(`${section}-create`) ? (
            <button className="btn-filter" type="button">
              <Link to={ id ? '/orders/form' : 'form' }>
                <span className="material-icons-outlined">playlist_add</span>
              </Link>
            </button>
          ) : null}
          <input
            className="inp-filter"
            type="search"
            value={ searchValue }
            onChange={ handleFilter }
          />
          <button className="btn-filter" type="button" onClick={ handleClean }>
            <span className="material-icons-outlined">backspace</span>
          </button>
        </div>
      ) : (
        ''
      )}
      {permissions?.includes(`${section}-read`) ? (
        <DataTable
          selectableRows={ selectRows }
          columns={ columnsTable }
          data={ searchValue?.length ? filteredItems : filteredData }
          customStyles={{
            headCells: {
              style: {
                backgroundColor: 'none'
              },
            },
          }}
          noDataComponent={ messageNoData }
          className={ `react-dataTable ${className} my-2` }
          expandableRows={ expandedRow }
          expandOnRowClicked
          expandableRowExpanded={ (row) => row === currentRow }
          onRowClicked={ (row) => setCurrentRow(row) }
          onRowExpandToggled={ (bool, row) => setCurrentRow(row) }
          expandableRowsComponent={ detailsTable }
          conditionalRowStyles={ conditionalStyles }
          onSelectedRowsChange={ onSelectedRowsChange }
          pagination={ pagination }
          paginationPerPage={ 10 }
          paginationDefaultPage={ currentPage + 1 }
          paginationComponent={ passthroughPagination }
        />
      ) : (
        <div>User does not have the credentials for this action.</div>
      )}
    </div>
  );
}

Table.propTypes = {
  filterEnabled: PropTypes.bool,
  className: PropTypes.string,
  conditionalStyles: PropTypes.arrayOf(PropTypes.shape),
  columnsTable: PropTypes.arrayOf(PropTypes.shape),
  filteredData: PropTypes.arrayOf(PropTypes.shape),
  filterOrders: PropTypes.string,
  conceptsFilter: PropTypes.arrayOf(PropTypes.shape),
  section: PropTypes.string,
  messageNoData: PropTypes.node,
  selectRows: PropTypes.bool,
  expandedRow: PropTypes.bool,
  detailsTable: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  pagination: PropTypes.bool,
};

Table.defaultProps = {
  filterEnabled: false,
  className: '',
  columnsTable: [],
  conditionalStyles: [],
  filteredData: [],
  filterOrders: '',
  conceptsFilter: [],
  section: '',
  messageNoData: '',
  selectRows: true,
  expandedRow: true,
  detailsTable: () => { },
  onSelectedRowsChange: () => { },
  pagination: true,
};

export default Table;
