import useQuotes from '../hooks/useQuotes';
import ButtonQuotesMod from './ButtonQuotesMod';

function QuotesColumnsMobile() {
  const { quotes } = useQuotes();

  const columnsTableMobile = [
    {
      name: 'N°',
      selector: (row) => row?.origin_order,
      sortable: true,
      width: '18%',
    },
    {
      name: 'CONTACTO',
      selector: (row) =>
        row?.client.fullname,
      sortable: true,
      width: '35%',
    },
    {
      name: 'FECHA',
      selector: (row) =>
        row?.start_date.slice(0, 10),
      sortable: true,
      width: '33%',
    },
    {
      name: '',
      cell: () => (
        null
      ),
      sortable: true,
      button: true,
      width: '0%',
    },
    {
      name: '',
      cell: (row) => ButtonQuotesMod(row),
      sortable: true,
      button: true,
      width: '5%',
    },
  ];
  return {
    columnsTableMobile, quotes
  };
}

export default QuotesColumnsMobile;
