import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectMeasurementUnit } from '../../../../data/redux/Units/measurementUnitSlice';
import useElementHider from '../../../hooks/useElementHider';

function ButtonUnitsMod(row) {
  const [toggle, setToggle] = useState(false);
  const deleteModifyButtonsRef = useElementHider(setToggle);
  const { permissions } = useSelector((state) => state.auth);

  function mod() {
    setToggle(!toggle);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = () => {
    dispatch(selectMeasurementUnit(row));
    navigate('/units/form');
  };

  return (
    <div ref={ deleteModifyButtonsRef }>
      {permissions.includes('client-delete') ||
        permissions.includes('client-update') ? (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modalEditarRol"
              onClick={ mod }
            >
              <span>&#10247;</span>
            </button>
          </div>
        ) : null}
      {toggle === true ? (
        <div className="container-button-mod container-button-admin">
          {permissions.includes('unit-update') ? (
            <button type="button" onClick={ handleChange }>
              Modificar
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default ButtonUnitsMod;
