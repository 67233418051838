import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../config/basequery';
import {
  checking,
  login,
  errorLogin,
  logout,
  errorLogout,
  changePassword,
  errorChangePassword,
  resetPassword,
  recoveryPassword,
  errorRecoveryPassword,
  errorResetPassword,
} from './authSlice';

export const checkingAuthentication = () => async (dispatch) => {
  dispatch(checking());
};

export const startLogin =
  ({ email, password }) =>
    async (dispatch) => {
      await dispatch(checking());

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_BACKEND}account/v1/login`,
          {
            email,
            password,
          }
        );
        await dispatch(login(data));
        toast.success(data.message);
      } catch ({ response }) {
        dispatch(errorLogin(response.data));
        toast.error(response.data.message);
      }
    };

export const startLogout = () => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}account/v1/logout`,
      config()
    );

    await dispatch(logout(data));
    toast.success(data.message);
  } catch ({ response }) {
    dispatch(errorLogout(response.data));
    toast.error(response.data.message);
  }
};

export const startChangePassword =
  ({ uid }, _body, navigate) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_BACKEND}account/v1/change-password/${uid}`,
          _body,
          config()
        );
        await dispatch(changePassword(data));
        toast.success(data.message);
        await dispatch(startLogout());

        navigate('/login');
      } catch ({ response }) {
        dispatch(errorChangePassword(response.data));
        toast.error(response.data.message);
      }
    };

export const startRecoveryPassword = (_body, navigate) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}account/v1/recovery-password`,
      _body
    );
    await dispatch(recoveryPassword(data));
    toast.success(data.message);

    navigate('/login');
  } catch ({ response }) {
    await dispatch(errorRecoveryPassword(response.data));
    toast.error(response.data.message);
  }
};

export const startResetPassword = (_body, navigate) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}account/v1/reset-password`,
      _body,
      config()
    );
    await dispatch(resetPassword(data));
    toast.success(data.message);
    navigate('/login');
  } catch ({ response }) {
    dispatch(errorResetPassword(response.data));
    toast.error(response.data.message);
  }
};
