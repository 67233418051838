import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

export const getQuotes = createAsyncThunk('order/getQuotes', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/orders?quotes=1`,
      config()
    );
    return data.orders;
  } catch (error) {
    toast(error);
    return [];
  }
});