import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

import { addPayment, onLoadingPayment } from './paymentSlice';

const createPayment = (form) => async (dispatch) => {
  dispatch(onLoadingPayment());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/payments`,
      form,
      config()
    );
    dispatch(addPayment(data));
    toast.success(data.message);
    return;
  } catch (error) {
    toast.error(error.response.data.message);
    const data = error.response.data.message.original.message;
    if (data) {
      Object.keys(data).forEach((key) => toast.error(data[key]));
    } else {
      toast.error('Error creating client');
    }
  }
};

export default createPayment;
