/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getClient from './thunks';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    order: [],
    editOrders: null,
    currentOrder: null,
    loading: true,
    clients: [],
    sectors: [],
    items: [],
    client: null,
    ordersClient: [],
    orderStatuses: [],
  },

  reducers: {
    addOrder: (state, { payload }) => {
      state.order = [...state.order, {
        ...payload
      }];
      state.editOrders = null;
    },

    editOrder: (state, { payload }) => {
      state.order = state.order.map((order) =>
        order.id === payload.id ? payload : order
      );
      state.editOrders = null;
    },

    editCloseOrder: (state, { payload }) => {
      state.order = payload;
    },

    editMarkCommOrder: (state, { payload }) => {
      state.order = payload;
    },

    deleteOrder: (state, { payload }) => {
      state.order = state.order.filter((order) => order.id !== payload);
      state.loading = false;
    },

    selectOrder: (state, { payload }) => {
      if (state?.length > 0) {
        state.editOrders = state.order.find((order) => order.id === payload.id);
      } else {
        state.editOrders = payload;
      }
    },

    selectVersion: (state, { payload }) => {
      state.currentOrder = state.editOrders;
      state.editOrders = payload;
    },

    selectCurrentVersion: (state, { payload }) => {
      state.editOrders = payload;
    },

    onLoadingOrder: (state) => {
      state.loading = true;
    },

    editOrders: (state, { payload }) => {
      state.order = payload;
    },
    editOrdersClient: (state, { payload }) => {
      state.ordersClient = payload;
      state.loading = false;
    },
    selectOrdersClient: (state, { payload }) => {
      state.ordersClient = payload.orders;
      state.loading = false;
    },
    selectClients: (state, { payload }) => {
      state.clients = payload.clients;
      state.loading = false;
    },
    selectSectors: (state, { payload }) => {
      state.sectors = payload.sectors;
      state.loading = false;
    },
    selectItems: (state, { payload }) => {
      state.items = payload.original.data;
      state.loading = false;
    },
    selectedClient: (state, { payload }) => {
      state.client = payload;
    },
    selectOrderStatuses: (state, { payload }) => {
      state.orderStatuses = payload;
      state.loading = false;
    },
    changeLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClient.fulfilled, (state, { payload }) => {
      state.order = payload;
      state.editOrders = null;
      state.currentOrder = null;
      state.clients = [];
      state.sectors = [];
      state.items = [];
      state.client = null;
      state.loading = false;
      state.ordersClient = [];
      state.orderStatuses = [];
    });
    builder.addCase(getClient.rejected, (state) => {
      state.order = 'fail';
      state.editOrders = null;
    });
  },
});

export const {
  addOrder,
  editOrder,
  deleteOrder,
  selectOrder,
  selectVersion,
  selectCurrentVersion,
  selectClients,
  selectOrdersClient,
  selectSectors,
  selectItems,
  selectedClient,
  onLoadingOrder,
  editOrders,
  selectOrderStatuses,
  changeLoading,
  editCloseOrder,
  editMarkCommOrder,
  editOrdersClient,
} = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
