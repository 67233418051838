export const emailValidator = (value) => {
  if (!value) {
    return 'El email no puede estar vacío';
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'El email no es válido';
  }
  return '';
};

export const passwordValidator = (value) => {
  if (!value) {
    return 'La contraseña no puede estar vacía';
  }
  if (value.length < 6) {
    return 'La contraseña debe tener al menos 6 caracteres';
  }
  return '';
};

export const confirmPasswordValidator = (password, repeatPassword) => {
  if (!repeatPassword) {
    return 'La contraseña no puede estar vacía';
  }
  if (repeatPassword.length < 6) {
    return 'La contraseña debe tener al menos 6 caracteres';
  }

  if (password !== repeatPassword) {
    return 'La contraseñas no coinciden';
  }

  return '';
};

export const textValidator = (value) => {
  if (!value) {
    return 'El campo no debe ir vacío';
  }
  return '';
};

export const selectValidator = (value) => {
  if (!value) {
    return 'Debe seleccionar una opción';
  }
  return '';
};

export const selectDateValidator = (value) => {
  if (!value) {
    return 'Debe seleccionar una fecha';
  }
  return '';
};

export const numberValidator = (value) => {
  if (
    typeof value === 'number' ||
    (typeof value === 'string' &&
      !Number.isNaN(parseFloat(value)) &&
      parseFloat(value) >= 0)
  ) {
    return '';
  }
  return 'Debe ingresar montos válidos para los campos';
};

export const filenameValidator = (value) => {
  const regex =
    /^(?!^(PRN|AUX|CLOCK\$|NUL|CON|COM\d|LPT\d|\..*)(\..+)?$)[^\x00-\x1f\\?*:";|/]+$/gm;
  if (!regex.test(value)) {
    return 'El nombre no es válido';
  }
  return '';
};

export const fullNameValidator = (value) => {
  if (!value) {
    return 'El nombre no debe ir vacío';
  }
  if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(value)) {
    return 'El nombre no es válido';
  }
  return '';
};

export const phoneValidator = (value) => {
  if (!value) {
    return 'El número de teléfono no debe ir vacío';
  }
  if (!/^[0-9]{1,14}$/.test(value)) {
    return 'El número de teléfono no es válido';
  }
  return '';
};

export const cuitValidator = (value) => {
  if (!value) {
    return 'El campo no puede estar vacío';
  }
  if (value.length < 8) {
    return 'No cumple la cantidad mínima de carácteres';
  }
  if (value.length === 8 && !/^\d{8}$/.test(value)) {
    return 'El DNI no es válido';
  }
  if (value.length > 8 && value.length < 11) {
    return 'Ingrese un DNI o una CUIT/CUIL válido';
  }
  if (
    value.length === 11 &&
    !/^(20|23|24|27|30|33|34)\d{9}(?:\d{2})?$/.test(value)
  ) {
    return 'La CUIT/CUIL no es válida';
  }
  if (value.length > 11) {
    return 'Excedió el número de carácteres';
  }
  return '';
};

export const specialCommValidator = (value) => {
  const valueAsNumber = parseFloat(value);
  if (valueAsNumber <= 5) {
    return 'Debe ingresar una comisión superior a 5%';
  }
  if (valueAsNumber > 100) {
    return 'La comisión no puede superar el 100%';
  }
  return '';
};

export const clientCommValidator = (value) => {
  const valueAsNumber = parseFloat(value);
  if (valueAsNumber > 5 || valueAsNumber < 0) {
    return 'Debe ingresar una comisión de 0 a 5%';
  }
  return '';
};

export const userCommValidator = (value) => {
  const valueAsNumber = parseFloat(value);
  if (valueAsNumber > 100 || valueAsNumber < 0) {
    return 'Debe ingresar una comisión de 0 a 100%';
  }
  return '';
};

export const percentValidator = (value) => {
  const valueAsNumber = parseFloat(value);
  if (valueAsNumber > 100 || valueAsNumber < 0) {
    return 'Debe ingresar un porcentaje del 0 a 100%';
  }
  // if (valueAsNumber > 100 || valueAsNumber < -100) {
  //   return 'Debe ingresar un porcentaje del -100 a 100%'
  // }
  return '';
};
