import React from 'react';
import { Navigate } from 'react-router-dom';
import FormNewPassword from '../components/LoginRegistro/FormNewPassword';
import FormLogin from '../components/LoginRegistro/Login';
import RecoverPassword from '../components/LoginRegistro/RecoverPassword';

const pagesData = [
  {
    path: 'login',
    element: <FormLogin />,
    title: 'Login',
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
    title: 'Not Found',
  },
  {
    path: '',
    element: <Navigate to="/login" />,
    title: 'Not Found',
  },
  {
    path: 'recovery',
    element: <RecoverPassword />,
    title: 'Not Found',
  },
  {
    path: 'reset-password/:token',
    element: <FormNewPassword />,
    title: 'Not Found',
  },
];

export default pagesData;
